import React, { useContext } from 'react';
import {
  Avatar,
  Box,
  Button,
  Divider,
  List,
  Typography
} from '@material-ui/core';
import { OfflineContext } from '@aldridge/aldg-helpers';
import * as serviceWorker from '../serviceWorkerRegistration';
import NavItem from './NavItem';
import { MenuData } from './Menu/MenuData';
import { UserContext } from '../providers/UserProvider';
import OnlineStatus from './OnlineStatus';
import IndexedDbWrapper from '../utils/indexedDBWrapper';
import { config } from '../firebase';
import CloudSync from './CloudSync';

const DashboardSidebar = () => {
  const user = useContext(UserContext) || {};
  const online = useContext(OfflineContext);
  const fRefreshApplication = () => {
    serviceWorker.unregister()
    window.location.reload();
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 2
        }}
      >
        <Avatar
          src={user.picture}
          sx={{
            cursor: 'pointer',
            width: 200,
            height: 200
          }}
        />
        <Typography color='textPrimary' variant='h5'>
          {user.name}
        </Typography>
        <Typography color='textSecondary' variant='body2'>
          {user.jobTitle}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ p: 2, pb: 1 }}>
        <List key="SidebarList">
          {user.admin ? MenuData.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          )) : MenuData.map(item => item.admin === false ?
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
            : <> </>)}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Box
        sx={{
          backgroundColor: 'background.default',
          m: 1,
          p: 1,
        }}
      >
        <div style={{ marginBottom: '24px', marginTop: '-12px', border: 'double' }}>
          <div style={{ display: 'flex', width: '100%', alignItems: 'center', marginRight: '4px' }}>
            <OnlineStatus />
            Current Online Status: {online ? 'Online' : 'Offline'}
          </div>
          <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
            <IndexedDbWrapper projectId={config.projectId} /> View Pending Records
          </div>
          <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
            <CloudSync /> Download Data
          </div>
        </div>
        <Typography align='center' gutterBottom variant='h4'>
          Need Help?
        </Typography>
        <div style={{ marginTop: '-12px', paddingBottom: '8px' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              pt: 1,

            }}
          >
            {
              online ? <Button color='primary' variant='contained' onClick={fRefreshApplication}>
                Force Refresh Application
              </Button> :
                <div style={{ color: 'red', fontWeight: 'bold' }}>You must be online to force refresh your application.</div>
            }

          </Box>
        </div>
        <Typography align='center' variant='body2'>
          Please contact Customer Support
        </Typography>
        {/* <Button color='primary' variant='contained' onClick={fRefreshApplication}>
            Force Refresh Application
          </Button> */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            pt: 1
          }}
        >
          <Button color='primary' component='a' href='' variant='contained'>
            Contact Aldridge
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardSidebar;
