/* eslint-disable no-unused-vars */

import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Button,
  FormHelperText,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { toast } from 'react-toastify';
import { Delete } from '@material-ui/icons';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Link } from 'react-router-dom';
import {
  InputText,
  InputRadio, InputRadioGroup,
} from '@aldridge/aldg-data-components';
import { useRecoilState, useSetRecoilState } from 'recoil';

import Confirm from '../_GlobalComponents/Confirm';
import { firestore } from '../firebase';
import { UserContext } from '../providers/UserProvider';
import { _AllSubcategories } from '../_Recoil/atoms';
import { basicStyles } from './theme';
import ErrorBoundary from '../_helpers/ErrorBoundary';

const SubcategoriesList = (props) => {
  const { history } = props;
  const [setups, setSetups] = useState([]);
  const [AllSubcategories] = useRecoilState(_AllSubcategories)

  const screenSizeWarning = () => <Alert severity="info">
    <AlertTitle>Attention</AlertTitle>
    Please use a tablet or computer to properly access this page!
  </Alert>

  useEffect(() => {
    let mounted = true
    if (mounted)
      setSetups(AllSubcategories);
    return () => { mounted = false }
  }, [AllSubcategories]);

  const onSelect = (id) => {
    history.push(`/Subcategories/${id}`);
  };
  const [deleteState, setDeleteState] = useState({
    proceedLabel: 'Delete',
    cancelLabel: 'Cancel',
    title: 'Delete Subcategory',
    modalBody: 'Are you sure you want to remove this Subcategory? Removing it will break some Inspections.',
    show: false,
    // eslint-disable-next-line no-use-before-define
    proceed: (approved, r) => { removeRecord(approved, r); },
    enableEscape: true
  });
  const removeRecord = (a, r) => {
    if (a) {
      if (r.idx !== '') {
        firestore.collection('Subcategories').doc(r.idx).delete();
      }
    }
    setDeleteState({ ...deleteState, show: false });
  };
  const handleDelete = (idx) => {
    setDeleteState({ ...deleteState, show: true, record: { idx } });
  };

  const classes = basicStyles();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));

  const getFlex = (f) => (matches ? null : f)

  const getWidth = (w) => (matches ? w : null)

  const columns = [
    {
      field: 'Subcategory', headerName: 'Subcategory',
      valueGetter: (params) => params.row.data.Subcategory,
      valueFormatter: (params) => params.row.data.Subcategory, flex: getFlex(10), width: getWidth(500)
    },
    {
      field: 'Status', headerName: 'Status',
      valueGetter: (params) => params.row.data.Status,
      valueFormatter: (params) => params.row.data.Status, flex: getFlex(5), width: getWidth(150)
    },
    {
      field: 'Delete', headerName: 'Delete', sortable: false, filterable: false, flex: getFlex(2), width: getWidth(100), align: 'right', renderCell: (params) =>
        <div><Delete style={{ cursor: 'pointer' }} onClick={(e) => { e.stopPropagation(); handleDelete(params.row.id); }} /></div>
    }
  ];
  return (
    <ErrorBoundary componentName="Subcategories List">
      {matches ? screenSizeWarning() : null}

      <Confirm
        proceedLabel={deleteState.proceedLabel}
        cancelLabel={deleteState.cancelLabel}
        title={deleteState.title}
        modalBody={deleteState.modalBody}
        show={deleteState.show}
        proceed={deleteState.proceed}
        enableEscape={deleteState.enableEscape}
        record={deleteState.record}
      />
      <div style={{
        position: 'sticky', top: 0, left: 0, right: 0, backgroundColor: 'white', width: '100%', display: 'flex', justifyContent: 'flex-end'
      }}
      >
        <Link style={{ margin: '16px' }} to="Subcategories/new"><Button variant="contained" color="primary">New Subcategory</Button></Link>
      </div>
      <div style={{
        width: '100%', display: 'flex', justifyContent: 'flex-end'
      }}
      />
      <div style={{ height: '100%' }}>
        <DataGrid
          className={classes.catRoot}
          getRowId={(row) => row.id}
          rows={setups}
          columns={columns}
          filterModel={{
            items: [
              { columnField: 'Subcategory', operatorValue: 'contains', value: '' },
              { columnField: 'Status', operatorValue: 'contains', value: '' }
            ],
          }}
          onRowClick={(a) => { onSelect(a.row.id); }}
        />
      </div>
    </ErrorBoundary>
  );
};

SubcategoriesList.propTypes = {
  history: PropTypes.objectOf(PropTypes.any)
};
SubcategoriesList.defaultProps = {
  history: {}
};

const Subcategories = (props) => {
  const { match, history } = props;
  const [saving, setSaving] = useState(false);
  const user = useContext(UserContext);
  const [AllSubcategories] = useRecoilState(_AllSubcategories)
  const setAllSubcategories = useSetRecoilState(_AllSubcategories)

  const [record, setRecord] = useState({
    id: '',
    CreatedDate: '',
    CreatedBy: '',
    ModifiedBy: '',
    ModifiedDate: '',
    data: {
      Subcategory: '',
      Status: 'Active',
    }
  });
  useEffect(() => {
    let mounted = true
    firestore.collection('Subcategories').doc(match.params.id).onSnapshot((snap) => {
      if (snap.exists) {
        const d = snap.data();
        if (mounted)
          setRecord(d);
      }
      else if (mounted) {
        setRecord({ ...record, id: snap.id });
      }
    });
    return () => { mounted = false }
  }, [match]);

  const handleSubmit = () => {
    setSaving(true);
    const colRef = firestore.collection('Subcategories');
    colRef.doc(record.id).set(record, { merge: true });
    setSaving(false);
    const allNewSubCategories = JSON.parse(JSON.stringify(AllSubcategories))
    allNewSubCategories.push(record)
    setAllSubcategories(allNewSubCategories)
    toast.success('Subcategory Saved Successfully.', { autoClose: 5000 });
    history.push('/Subcategories');
  };
  const handleChange = (event, name, displayFromTypeahead) => {
    try {
      const id = name || event.target.name;
      const value = typeof name !== 'undefined' ? event : event.target.value;
      const changeRecord = { ...record };
      changeRecord.data[id] = value;
      if (typeof displayFromTypeahead !== 'undefined') {
        changeRecord.data[`${id}Display`] = displayFromTypeahead;
      }
      if (changeRecord.CreatedBy === '') {
        changeRecord.CreatedBy = user.name;
        changeRecord.CreatedDate = new Date().toJSON();
      }
      changeRecord.ModifiedBy = user.email;
      changeRecord.ModifiedDate = new Date().toJSON();
      setRecord(changeRecord);
    }
    catch (err) {
      toast.error(err.message);
    }
  };

  return (
    <ErrorBoundary componentName="Subcategory">
      {
        saving ? (
          <div style={{
            display: 'block', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: '#efefef', opacity: 0.5, zIndex: 100
          }}
          />
        ) : <></>
      }
      <div style={{
        position: 'sticky', top: 0, left: 0, right: 0, width: '100%', backgroundColor: 'white', paddingTop: '10px', paddingBottom: '5px', paddingLeft: '0px', display: 'flex', justifyContent: 'flex-end', zIndex: 100
      }}
      >
        <Button variant="contained" color="primary" onClick={handleSubmit}>Save</Button>
        <Link style={{ textDecoration: 'none' }} to="/Subcategories"><Button variant="contained" style={{ marginLeft: '5px', color: 'black' }}>Close</Button></Link>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <InputText name="Subcategory" value={record.data.Subcategory || ''} label="Subcategory" onChange={handleChange} />
        </Grid>
        <Grid item xs={12}>
          <InputRadioGroup name="Status" value={record.data.Status || ''} label="Status" onChange={handleChange}>
            <InputRadio value="Active" label="Active" style={{ transform: 'scale(1.25)' }} />
            <InputRadio value="Inactive" label="Inactive" style={{ transform: 'scale(1.25)' }} />
          </InputRadioGroup>
          <FormHelperText>Inactivating a Subcategory will only
            prevent this from showing up for future Inspections.
          </FormHelperText>
        </Grid>
      </Grid>
    </ErrorBoundary>
  );
};

Subcategories.propTypes = {
  history: PropTypes.objectOf(PropTypes.any),
  match: PropTypes.objectOf(PropTypes.any),
};
Subcategories.defaultProps = {
  match: {},
  history: {},
};

export {
  SubcategoriesList, Subcategories
};
