import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField, ThemeProvider } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { RedAsterisk } from '../../providers/MuiTheme';
import uuid from '../../_helpers/uuid';

const InputTypehead = (props) => {
  const {
    disableClearable,
    disabled,
    label,
    id,
    fullWidth,
    hidden,
    invalid,
    onChange,
    readOnly,
    required,
    options,
    value,
    variant,
    name,
    clearOnBlur,
    blurOnSelect,
    freeSolo
  } = props;

  const [InputValue, setInputValue] = useState('');

  const onInput = (e) => {
    setInputValue(e.target.value);
  };
  return (
    <ThemeProvider theme={RedAsterisk}>
      <Autocomplete
        id={id || uuid()}
        freeSolo={freeSolo}
        getOptionLabel={(option) => option.label}
        options={options}
        name={name}
        fullWidth={fullWidth}
        inputValue={InputValue}
        renderInput={(params) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <TextField {...params} label={`${label}`} fullWidth={fullWidth} margin="normal" variant={variant} required={required} error={invalid} onInput={onInput} />)}
        onChange={(e, v) => { onChange(e, v); setInputValue(''); }}
        disabled={disabled}
        value={value}
        hidden={hidden}
        readOnly={readOnly}
        blurOnSelect={blurOnSelect}
        clearOnBlur={clearOnBlur}
        disableClearable={disableClearable}
      />

    </ThemeProvider>
  );
};
InputTypehead.propTypes = {
  disableClearable: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  selectLabel: PropTypes.string,
  id: PropTypes.string,
  fullWidth: PropTypes.bool,
  hidden: PropTypes.bool,
  inputProps: PropTypes.objectOf(PropTypes.any),
  invalid: PropTypes.bool,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  freeSolo: PropTypes.bool,
  variant: PropTypes.string,
  name: PropTypes.string.isRequired,
  multiple: PropTypes.bool,

  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.number)
  ]),
  valueKey: PropTypes.string,
  optionDisplay: PropTypes.arrayOf(PropTypes.string),
  where: PropTypes.arrayOf(PropTypes.any),
  orderBy: PropTypes.string,
  disableToken: PropTypes.bool,
  renderToken: PropTypes.func,
  requirement: PropTypes.arrayOf(PropTypes.string),
  clearOnBlur: PropTypes.bool,
  blurOnSelect: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.any)
};
InputTypehead.defaultProps = {
  freeSolo: true,
  disableClearable: false,
  disabled: false,
  label: 'No Label Provided',
  selectLabel: 'Select a Record...',
  id: undefined,
  fullWidth: true,
  hidden: false,
  inputProps: {},
  invalid: false,
  onChange: () => { },
  readOnly: false,
  required: false,
  variant: undefined,
  multiple: false,

  value: undefined,
  valueKey: '',
  optionDisplay: [],
  where: undefined,
  orderBy: undefined,
  disableToken: false,
  renderToken: undefined,
  requirement: undefined,
  clearOnBlur: false,
  blurOnSelect: false,
  options: []
};
export default InputTypehead;
