/* eslint-disable no-unused-vars */

import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Button,
  TableBody,
  FormHelperText,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import { Delete } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import {
  InputText,
  InputRadioGroup, InputRadio
} from '@aldridge/aldg-data-components';
import { useRecoilState, useSetRecoilState } from 'recoil';
import Confirm from '../_GlobalComponents/Confirm';
import uuid from '../_helpers/uuid';
import { firestore } from '../firebase';
import { UserContext } from '../providers/UserProvider';
import SortableList from '../_DataComponents/SortableList';
import { InputTypeahead } from '../_DataComponents/FormFields';
import { _AllInspectionTypes } from '../_Recoil/atoms';
import { basicStyles } from './theme';
import ErrorBoundary from '../_helpers/ErrorBoundary';

const InspectionTypesList = (props) => {
  const { history } = props;
  const [setups, setSetups] = useState([]);
  const [AllInspectionTypes] = useRecoilState(_AllInspectionTypes);

  const classes = basicStyles();

  useEffect(() => {
    let mounted = true;
    if (mounted)
      setSetups(AllInspectionTypes);
    return () => { mounted = false; };
  }, [AllInspectionTypes]);

  const onSelect = (id) => {
    history.push(`/InspectionTypes/${id}`);
  };
  const [deleteState, setDeleteState] = useState({
    proceedLabel: 'Delete',
    cancelLabel: 'Cancel',
    title: 'Delete Inspection Type',
    modalBody: 'Are you sure you want to remove this Inpsection Type? Removing it will break some Inspections.',
    show: false,
    // eslint-disable-next-line no-use-before-define
    proceed: (approved, r) => { removeRecord(approved, r); },
    enableEscape: true
  });
  const removeRecord = (a, r) => {
    if (a) {
      if (r.idx !== '') {
        firestore.collection('InspectionTypes').doc(r.idx).delete();
      }
    }
    setDeleteState({ ...deleteState, show: false });
  };
  const handleDelete = (idx) => {
    setDeleteState({ ...deleteState, show: true, record: { idx } });
  };
  return (
    <ErrorBoundary componentName="Inspection Types List">
      <Confirm
        proceedLabel={deleteState.proceedLabel}
        cancelLabel={deleteState.cancelLabel}
        title={deleteState.title}
        modalBody={deleteState.modalBody}
        show={deleteState.show}
        proceed={deleteState.proceed}
        enableEscape={deleteState.enableEscape}
        record={deleteState.record}
      />
      <div style={{
        position: 'sticky', top: 0, left: 0, right: 0, backgroundColor: 'white', width: '100%', display: 'flex', justifyContent: 'flex-end'
      }}
      >
        <Link style={{ margin: '16px' }} to="InspectionTypes/new"><Button variant="contained" color="primary">New Inspection Type</Button></Link>
      </div>
      <Grid container spacing={0}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: 'bold' }}>Inspection Type</TableCell>
                <TableCell style={{ fontWeight: 'bold' }} colSpan={2}>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {setups.map((row) => (
                <TableRow className={classes.row1} style={{ cursor: 'pointer' }} hover onClick={() => onSelect(row.id)} key={uuid()}>
                  <TableCell>{row.data.InspectionType}</TableCell>
                  <TableCell>{row.data.Status}</TableCell>
                  <TableCell align="right"><Delete onClick={(e) => { e.stopPropagation(); handleDelete(row.id); }} /></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </ErrorBoundary>
  );
};

InspectionTypesList.propTypes = {
  history: PropTypes.objectOf(PropTypes.any)
};
InspectionTypesList.defaultProps = {
  history: {}
};

const InspectionTypes = (props) => {
  const { match, history } = props;
  const [saving, setSaving] = useState(false);
  const [Categories, setCategories] = useState([]);
  const [AllInspectionTypes] = useRecoilState(_AllInspectionTypes);
  const setAllInspectionTypes = useSetRecoilState(_AllInspectionTypes)

  const user = useContext(UserContext);
  const [record, setRecord] = useState({
    id: '',
    CreatedDate: '',
    CreatedBy: '',
    ModifiedBy: '',
    ModifiedDate: '',
    data: {
      InspectionType: '',
      Status: 'Active',
      Categories: [],
    }
  });
  useEffect(() => {
    let mounted = true;
    firestore.collection('InspectionTypes').doc(match.params.id).onSnapshot((snap) => {
      if (snap.exists) {
        const d = snap.data();
        if (mounted)
          setRecord(d);
      }
      else if (mounted) {
        setRecord({ ...record, id: snap.id });
      }
    });
    return () => { mounted = false; };
  }, [match]);

  useEffect(() => {
    let mounted = true;
    firestore.collection('Categories').orderBy('data.Category').onSnapshot((snap) => {
      const allSubs = [];
      snap.forEach((doc) => {
        allSubs.push(doc.data());
      });
      if (mounted)
        setCategories(allSubs);
      return () => { mounted = false; };
    }, (err) => toast.error(err));
  }, []);

  const handleSubmit = () => {
    setSaving(true);
    const colRef = firestore.collection('InspectionTypes');
    colRef.doc(record.id).set(record, { merge: true });
    setSaving(false);
    const allNewInspectionTypes = JSON.parse(JSON.stringify(AllInspectionTypes))
    allNewInspectionTypes.push(record)
    setAllInspectionTypes(allNewInspectionTypes)
    toast.success('Inspection Type Saved Successfully.', { autoClose: 5000 });
    history.push('/InspectionTypes');
  };
  const handleChange = (event, name, displayFromTypeahead) => {
    try {
      const id = name || event.target.name;
      const value = typeof name !== 'undefined' ? event : event.target.value;
      const changeRecord = { ...record };
      changeRecord.data[id] = value;
      if (typeof displayFromTypeahead !== 'undefined') {
        changeRecord.data[`${id}Display`] = displayFromTypeahead;
      }
      if (changeRecord.CreatedBy === '') {
        changeRecord.CreatedBy = user.name;
        changeRecord.CreatedDate = new Date().toJSON();
      }
      changeRecord.ModifiedBy = user.email;
      changeRecord.ModifiedDate = new Date().toJSON();
      setRecord(changeRecord);
    }
    catch (err) {
      toast.error(err.message);
    }
  };

  const addCategory = (e, v) => {
    const updatedRecord = { ...record };
    if (v === null || typeof v === 'undefined') return;
    if (updatedRecord.data.Categories.filter((res) => res.id === v.value).length === 0) {
      const subRecord = Categories.filter((res) => res.id === v.value);
      if (subRecord.length > 0) {
        updatedRecord.data.Categories.push(subRecord[0]);
        setRecord(updatedRecord);
      }
    }
  };
  const removeFromList = (idx) => {
    const updatedRecord = { ...record };
    updatedRecord.data.Categories.splice(idx, 1);
    setRecord(updatedRecord);
  };
  const updateOrder = (reOrdered) => {
    const updatedRecord = { ...record };
    updatedRecord.data.Categories = reOrdered;
    setRecord(updatedRecord);
  };
  return (
    <ErrorBoundary componentName="Inspection Types (Not List)">
      {
        saving ? (
          <div style={{
            display: 'block', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: '#efefef', opacity: 0.5, zIndex: 100
          }}
          />
        ) : <></>
      }
      <div style={{
        position: 'sticky', top: 0, left: 0, right: 0, width: '100%', backgroundColor: 'white', paddingTop: '10px', paddingBottom: '5px', paddingLeft: '0px', display: 'flex', justifyContent: 'flex-end', zIndex: 100
      }}
      >
        <Button variant="contained" color="primary" onClick={handleSubmit}>Save</Button>
        <Link style={{ textDecoration: 'none' }} to="/InspectionTypes"><Button variant="contained" style={{ marginLeft: '5px', color: "black" }}>Close</Button></Link>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <InputText name="InspectionType" value={record.data.InspectionType || ''} label="Inspection Type" onChange={handleChange} />
        </Grid>
        <Grid item xs={12}>
          <InputRadioGroup name="Status" value={record.data.Status || ''} label="Status" onChange={handleChange}>
            <InputRadio value="Active" label="Active" style={{ transform: 'scale(1.25)' }} />
            <InputRadio value="Inactive" label="Inactive" style={{ transform: 'scale(1.25)' }} />
          </InputRadioGroup>
          <FormHelperText>Inactivating an Inspection Type will only
            prevent this from showing up for future Inspections.
          </FormHelperText>
        </Grid>
        <Grid item xs={12}>
          <InputTypeahead label="Add a Category" name="Category" onChange={addCategory} options={Categories.map((res) => ({ label: res.data.Category, value: res.id }))} clearOnBlur blurOnSelect />
          <SortableList options={record.data.Categories || []} displayKey="Category" updateOrder={updateOrder} removeFromList={removeFromList} />
        </Grid>
      </Grid>
    </ErrorBoundary>
  );
};

InspectionTypes.propTypes = {
};
InspectionTypes.defaultProps = {
};
InspectionTypes.propTypes = {
  history: PropTypes.objectOf(PropTypes.any),
  match: PropTypes.objectOf(PropTypes.any),
};
InspectionTypes.defaultProps = {
  history: {},
  match: {},
};

export {
  InspectionTypesList, InspectionTypes
};
