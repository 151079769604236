import React from 'react';
import PropTypes from 'prop-types';
import {
  TextField, ThemeProvider
} from '@material-ui/core';
import { RedAsterisk } from '../../providers/MuiTheme';
import uuid from '../../_helpers/uuid';

const InputTextarea = (props) => {
  const {
    disabled,
    label,
    id,
    fullWidth,
    hidden,
    inputProps,
    invalid,
    onChange,
    readOnly,
    required,
    value,
    variant,
    name
  } = props;

  return (
    <ThemeProvider theme={RedAsterisk}>
      <TextField
        type="textarea"
        disabled={disabled}
        label={label}
        id={id || uuid()}
        variant={variant}
        hidden={hidden}
        fullWidth={fullWidth}
        inputProps={inputProps}
        error={invalid}
        name={name}
        onChange={onChange}
        readOnly={readOnly}
        value={value}
        required={required}
        multiline
      />
    </ThemeProvider>
  );
};
InputTextarea.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  id: PropTypes.string,
  fullWidth: PropTypes.bool,
  hidden: PropTypes.bool,
  inputProps: PropTypes.objectOf(PropTypes.any),
  invalid: PropTypes.bool,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.string,
  variant: PropTypes.string,
  name: PropTypes.string.isRequired
};
InputTextarea.defaultProps = {
  disabled: false,
  label: 'No Label Provided',
  id: undefined,
  fullWidth: true,
  hidden: false,
  inputProps: {},
  invalid: false,
  onChange: () => {},
  readOnly: false,
  required: false,
  value: '',
  variant: undefined
};
export default InputTextarea;
