/* eslint-disable no-await-in-loop */
import React, {
  useState,
  useEffect
} from 'react';
import PropTypes from 'prop-types';
import { InputLabel, Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEye,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

import Gallery from 'react-grid-gallery';
import Loader from 'react-loader-spinner';
import { Offline, Online } from 'react-detect-offline';
import { storage } from '../firebase';
import { useFirebaseMutation } from '../_helpers/fetch';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Confirm from '../_GlobalComponents/Confirm';
import { WordIcon, PDFIcon, ExcelIcon } from './ImageFileBase64';
import ErrorBoundary from '../_helpers/ErrorBoundary';


const Files = (props) => {
  const {
    label,
    name,
    multiple,
    bucket,
    uploadText,
    zipText,
    folder,
    fileCount,
    disabled
  } = props;
  const [index, setIndex] = useState(0);
  const [createLoading, setCreateLoading] = useState(false);
  const [createError, setCreateError] = useState(undefined);

  const [files, setFiles] = useState([]);
  const [filesLoading, setFilesLoading] = useState(false);
  const [filesError] = useState(undefined);

  const {
    data, loading: zipLoading, error, firebaseCall
  } = useFirebaseMutation('zipBucket', { bucket, path: folder });

  let FILES = [];
  if (!filesLoading && files.length > 0) {
    FILES = files.map((file) => {
      if (file.contentType.indexOf('image/') > -1) {
        return {
          src: file.url,
          thumbnail: file.thumbnail || file.url,
          thumbnailWidth: 75,
          thumbnailHeight: 75,
          name: file.name,
          caption: file.name.replace(`${folder}/`, ''),
          contentType: file.contentType,
          url: file.url
        };
      }
      if (file.contentType.indexOf('application/vnd.ms-excel') > -1 || file.contentType.indexOf('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') > -1) {
        return {
          src: ExcelIcon,
          thumbnail: ExcelIcon,
          thumbnailWidth: 180,
          thumbnailHeight: 180,
          name: file.name,
          caption: file.name.replace(`${folder}/`, ''),
          contentType: file.contentType,
          url: file.url
        };
      }
      if (file.contentType.indexOf('/pdf') > -1) {
        return {
          src: PDFIcon,
          thumbnail: PDFIcon,
          thumbnailWidth: 180,
          thumbnailHeight: 180,
          name: file.name,
          caption: file.name.replace(`${folder}/`, ''),
          contentType: file.contentType,
          url: file.url
        };
      }
      return {
        src: WordIcon,
        thumbnail: WordIcon,
        thumbnailWidth: 180,
        thumbnailHeight: 180,
        name: file.name,
        caption: file.name.replace(`${folder}/`, ''),
        contentType: file.contentType,
        url: file.url
      };
    });
  }

  useEffect(() => {
    if (typeof error !== 'undefined') {
      toast.error(error);
    }
  }, [error]);
  const viewFile = () => {
    const file = files[index];
    const anchor = document.createElement('a'); anchor.href = file.url; anchor.target = '_blank'; anchor.click();
  };
  const onCurrentImageChange = (idx) => {
    setIndex(idx);
  };
  const getFiles = async (f) => {
    const folderTemp = f || folder;
    const ref = storage.ref(folderTemp);
    const thumbs = storage.ref(`${folderTemp}/thumbnail`);
    const items = [];
    const listItems = (await ref.listAll()).items;
    const listOfThumbs = (await thumbs.listAll()).items;
    for (let i = 0; i < listItems.length; i++) {
      const url = await listItems[i].getDownloadURL();
      const metaData = await listItems[i].getMetadata();
      if (metaData.contentType !== 'application/zip') {
        if (metaData.contentType.indexOf('image/') > -1) {
          const thumbLink = await (async () => {
            const a = listOfThumbs.filter((res) => res.name === listItems[i].name);
            if (a.length > 0) {
              const link = (await a[0].getDownloadURL());
              return link;
            }
            return undefined;
          })();
          items.push({
            thumbnail: thumbLink,
            contentType: metaData.contentType,
            url,
            width: metaData.customMetadata.width,
            height: metaData.customMetadata.height,
            name: metaData.name
          });
        }
        else {
          items.push({ contentType: metaData.contentType, url, name: metaData.name });
        }
      }
    }

    return items;
  };
  const uploadFiles = () => {
    const fileUpBtn = document.createElement('input');
    fileUpBtn.type = 'file';
    if (multiple) {
      fileUpBtn.multiple = true;
    }
    fileUpBtn.name = name;
    fileUpBtn.id = name;
    fileUpBtn.style = 'display:none;';
    const upFile = (fileToUpload) => new Promise((resolve, reject) => {
      const ref = storage.ref();
      let metadata = {};
      if (fileToUpload.type.indexOf('image/') > -1) {
        const fr = new FileReader();
        fr.readAsDataURL(fileToUpload);
        fr.onload = () => {
          const img = new Image();
          img.src = fr.result;
          img.onload = () => {
            metadata = {
              customMetadata: {
                width: img.width,
                height: img.height
              }
            };
            const filename = fileToUpload.name.split('.');
            const f = ref.child(`${folder}/${filename[0].replace(/\//g, '_').replace(/ /g, '_')}.${filename[1]}`);
            const task = f.put(fileToUpload, metadata);
            task.then(() => {
              toast.success('File Uploaded Successfully.', { autoClose: 2000 });
              resolve();
            });
            task.catch((err) => {
              toast.error(`File ${fileToUpload.name} Failed To Upload. Please try re-uploading when you have better connection.`, { autoClose: 8000 });
              // eslint-disable-next-line
              console.log(err);
              setCreateError(err.message);
              reject();
            });
          };
        };
      }
      else {
        const f = ref.child(`${folder}/${fileToUpload.name.replace(/\//g, '_').replace(/ /g, '_')}`);
        const task = f.put(fileToUpload);
        task.then(() => {
          toast.success('File Uploaded Successfully.', { autoClose: 2000 });
          resolve();
        });
        task.catch((err) => {
          toast.error(`File ${fileToUpload.name} Failed To Upload. Please try re-uploading when you have better connection.`, { autoClose: 8000 });
          // eslint-disable-next-line
          console.log(err);
          setCreateError(err.message);
          reject();
        });
      }
    });
    const p = [];
    fileUpBtn.addEventListener('change', (e) => {
      setCreateLoading(true);
      setCreateError(undefined);
      for (let i = 0; i < e.target.files.length; i++) {
        p.push(upFile(e.target.files[i]));
      }
      Promise.all(p).then(() => {
        document.body.removeChild(fileUpBtn);
        setCreateLoading(false);
        setFilesLoading(true);
        getFiles().then((items) => {
          setFilesLoading(false);
          setFiles(items);
          if (typeof fileCount !== 'undefined') {
            fileCount(items.length);
          }
        });
      }).catch((err) => {
        // eslint-disable-next-line
        console.log(err);
        setCreateLoading(false);
      });
    });
    document.body.appendChild(fileUpBtn);
    fileUpBtn.click();
  };

  const [approvalState, setApprovalState] = useState({
    proceedLabel: 'Delete',
    cancelLabel: 'Cancel',
    title: 'Delete File',
    modalBody: 'Would you like to delete this file? Deleting it is permanent.',
    show: false,
    // eslint-disable-next-line no-use-before-define
    proceed: (approved, r) => { uncheckRec(approved, r); },
    enableEscape: true,
    record: undefined
  });
  const uncheckRec = (approved, obj) => {
    if (approved) {
      const { domElem, file } = obj.record;
      const f = obj.folder;
      document.body.appendChild(domElem);
      const uploadTask = storage.ref().child(`${f}/${file.name}`).delete();
      storage.ref().child(`${f}/thumbnail/${file.name}`).delete();
      uploadTask.then(() => {
        document.body.removeChild(domElem);
        toast.success('File Deleted Successfully.', { autoClose: 2000 });
        setFilesLoading(true);
        getFiles(f).then((items) => {
          setFilesLoading(false);
          setFiles(items);
          if (typeof fileCount !== 'undefined') {
            fileCount(items.length);
          }
        });
      }, (err) => {
        // eslint-disable-next-line
        console.log(err);
      });
    }
    setApprovalState({ ...approvalState, show: false });
  };
  const handleApproval = (record) => {
    setApprovalState({ ...approvalState, show: true, record: { record, folder } });
  };
  const deleteFile = () => {
    const file = FILES[index];
    const deleteLoader = document.createElement('div');
    deleteLoader.style = 'display:flex;flex-direction:column; justify-content:center; align-items:center; z-index:9999; position: fixed; top: 0; left: 0; right: 0; bottom: 0;background-color: rgba(255,255,255,.5);';
    deleteLoader.innerHTML = 'Deleting File<br><svg width="75" height="75" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" aria-label="audio-loading"><defs><linearGradient x1="8.042%" y1="0%" x2="65.682%" y2="23.865%" id="a"><stop stop-color="#3b78e7" stop-opacity="0" offset="0%"></stop><stop stop-color="#3b78e7" stop-opacity=".631" offset="63.146%"></stop><stop stop-color="#3b78e7" offset="100%"></stop></linearGradient></defs><g fill="none" fill-rule="evenodd"><g transform="translate(1 1)"><path d="M36 18c0-9.94-8.06-18-18-18" id="Oval-2" stroke="#3b78e7" stroke-width="2"><animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="0.9s" repeatCount="indefinite"></animateTransform></path><circle fill="#fff" cx="36" cy="18" r="1"><animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="0.9s" repeatCount="indefinite"></animateTransform></circle></g></g></svg>';
    handleApproval({ domElem: deleteLoader, file });
  };

  const zipFiles = () => {
    firebaseCall();
  };

  useEffect(() => {
    if (typeof filesError !== 'undefined') {
      toast.error(`File Failed to Load: ${filesError}`);
    }
    if (typeof createError !== 'undefined') {
      toast.error(`File Failed to Post: ${createError}`);
    }
  }, [filesError, createError]);

  useEffect(() => {
    if (data !== undefined && data.length > 0) {
      const zipDownload = document.createElement('a');
      zipDownload.href = data.pop();
      zipDownload.download = `${folder.split('/')[folder.split('/').length - 1]}`;
      zipDownload.click();
    }
  }, [data, error, zipLoading]);

  useEffect(() => {
    let mounted = true;
    if (mounted)
      setFilesLoading(true);
    getFiles().then((items) => {
      if (mounted) {
        setFilesLoading(false);
        setFiles(items);
        if (typeof fileCount !== 'undefined') {
          fileCount(items.length);
        }
      }
    });
    return () => (mounted = false);
  }, [folder]);

  return (
    <ErrorBoundary componentName="Files">
      <Confirm
        proceedLabel={approvalState.proceedLabel}
        cancelLabel={approvalState.cancelLabel}
        title={approvalState.title}
        modalBody={approvalState.modalBody}
        show={approvalState.show}
        proceed={approvalState.proceed}
        enableEscape={approvalState.enableEscape}
        record={approvalState.record}
      />
      <div>
        <Online>
          <InputLabel htmlFor={name}>{label}</InputLabel>
          <br />
          {!disabled ? <Button id={name} variant="contained" onClick={uploadFiles}>{uploadText}</Button> : null}
          &nbsp;
          {!disabled ? <Button variant="contained" onClick={zipFiles}>{zipText}</Button> : <></>}
          {/* {true ? ( */}
          {createLoading ? (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Loader
                type="TailSpin"
                color="#3b78e7"
                height={30}
                width={30}
              />
              Uploading File...

            </div>
          ) : null}
        </Online>
        <Offline>
          <div style={{ color: 'red', padding: '10px', fontWeight: '600' }}>There is no internet access. Please try adding photos when you have better connection.</div>
        </Offline>
      </div>
      {filesLoading ? (
        // {true ? (
        <div style={{ width: '100%', textAlign: 'center' }}>
          Loading Photos...
          <Loader
            type="TailSpin"
            color="#3b78e7"
            height={100}
            width={100}
          />
        </div>
      ) : null}
      {zipLoading ? (
        // {true ? (
        <div style={{ width: '100%', textAlign: 'center' }}>
          Zipping Files...
          <Loader
            type="TailSpin"
            color="#3b78e7"
            height={100}
            width={100}
          />
        </div>
      ) : null}
      {
        FILES.length > 0
          ? (
            <div style={{ paddingTop: '5px' }}>
              <Gallery
                images={FILES}
                enableImageSelection={false}
                currentImageWillChange={onCurrentImageChange}
                customControls={[
                  <div key="deleteImage" style={{ display: 'flex', alignItems: 'center' }}><FontAwesomeIcon icon={faTrash} className="text-light" style={{ cursor: 'pointer', fontSize: '20px', color: 'white' }} onClick={deleteFile} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={faEye} className="text-light" style={{ cursor: 'pointer', fontSize: '20px', color: 'white' }} onClick={viewFile} /></div>
                ]}
                backdropClosesModal
              />
              <div style={{ float: 'none', clear: 'both' }} />
            </div>
          )
          : null
      }
    </ErrorBoundary>
  );
};
Files.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  multiple: PropTypes.bool,
  zipText: PropTypes.string,
  uploadText: PropTypes.string,
  folder: PropTypes.string.isRequired,
  bucket: PropTypes.string.isRequired,
  fileCount: PropTypes.func,
  disabled: PropTypes.bool
};
Files.defaultProps = {
  label: 'Files',
  name: 'files',
  zipText: 'Zip All Files',
  uploadText: 'Upload Files',
  multiple: false,
  fileCount: undefined,
  disabled: false
};
export default Files;
