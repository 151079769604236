/* eslint-disable no-unused-vars */
import React, { useContext } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { Search, InputDate } from '@aldridge/aldg-data-components';
import { useRecoilState } from 'recoil';
import { AccordionBlueClass, AccordionBlueDetailsClass, AccordionBlueSummaryClass } from '../providers/MuiTheme';
import { _CurrentInspectionDateDate, _CurrentJobNumberDisplay, _CurrentStatus, _CurrentInspector, _InspectionsFilterQuery } from '../_Recoil/atoms';
import { firestore } from '../firebase';
import { UserContext } from '../providers/UserProvider';


const InspectionsFilter = () => {
  const user = useContext(UserContext);
  const [CurrentJobNumberDisplay, setCurrentJobNumberDisplay] = useRecoilState(_CurrentJobNumberDisplay);
  const [CurrentStatus, setCurrentStatus] = useRecoilState(_CurrentStatus);
  const [CurrentInspectionDateDate, setCurrentInspectionDateDate] = useRecoilState(_CurrentInspectionDateDate);
  const [CurrentInspector, setCurrentInspector] = useRecoilState(_CurrentInspector);
  const [InspectionsFilterQuery, setInspectionsFilterQuery] = useRecoilState(_InspectionsFilterQuery);

  const onChange = (event) => {
    const filteredDate = (event.target.value)
    setCurrentInspectionDateDate(filteredDate);

    const queryOptions = { ...InspectionsFilterQuery };
    queryOptions.limit = 100;
    queryOptions.offset = 0;
    queryOptions.sort = null;
    queryOptions.direction = null;
    queryOptions.lastDoc = null;
    queryOptions.JobNumberDisplay = CurrentJobNumberDisplay;
    queryOptions.Status = CurrentStatus;
    queryOptions.InspectionDateDate = filteredDate;
    queryOptions.Inspector = CurrentInspector;

    setInspectionsFilterQuery(queryOptions);
  };

  const FilterJobNumberDisplay = (obj) => {
    const val = obj === null ? '' : obj;
    setCurrentJobNumberDisplay(val);
    const queryOptions = { ...InspectionsFilterQuery };
    queryOptions.limit = 100;
    queryOptions.offset = 0;
    queryOptions.sort = null;
    queryOptions.direction = null;
    queryOptions.lastDoc = null;
    queryOptions.JobNumberDisplay = val;
    queryOptions.Status = CurrentStatus;
    queryOptions.InspectionDateDate = CurrentInspectionDateDate;
    queryOptions.Inspector = CurrentInspector;

    setInspectionsFilterQuery(queryOptions);
  };
  const FilterInspector = (obj) => {
    const val = obj === null ? '' : obj;

    setCurrentInspector(val);
    const queryOptions = { ...InspectionsFilterQuery };
    queryOptions.limit = 100;
    queryOptions.offset = 0;
    queryOptions.sort = null;
    queryOptions.direction = null;
    queryOptions.lastDoc = null;
    queryOptions.JobNumberDisplay = CurrentJobNumberDisplay;
    queryOptions.Status = CurrentStatus;
    queryOptions.InspectionDateDate = CurrentInspectionDateDate;
    queryOptions.Inspector = val;
    setInspectionsFilterQuery(queryOptions);
  };

  const FilterStatus = (obj) => {
    const val = obj === null ? '' : obj;
    setCurrentStatus(val);
    const queryOptions = { ...InspectionsFilterQuery };
    queryOptions.limit = 100;
    queryOptions.offset = 0;
    queryOptions.sort = null;
    queryOptions.direction = null;
    queryOptions.lastDoc = null;
    queryOptions.JobNumberDisplay = CurrentJobNumberDisplay;
    queryOptions.Status = val;
    queryOptions.InspectionDateDate = CurrentInspectionDateDate;
    queryOptions.Inspector = CurrentInspector;
    setInspectionsFilterQuery(queryOptions);
  };

  return (
    <Accordion classes={AccordionBlueClass()}>
      <AccordionSummary classes={AccordionBlueSummaryClass()}

        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        Filters
      </AccordionSummary>
      <AccordionDetails classes={AccordionBlueDetailsClass()}>
        <Grid container spacing={2}>
          {
            user.admin ?
              <Grid item md={6} xs={12}>
                <Search
                  label="Inspector"
                  name="Inspector"
                  searchfnc={FilterInspector}
                  firestore={firestore}
                  value={CurrentInspector}
                />
              </Grid>
              : null
          }
          <Grid item md={!user.admin ? 12 : 6} xs={12}>
            <Search
              label="Job Number"
              name="JobNumberDisplay"
              searchfnc={FilterJobNumberDisplay}
              firestore={firestore}
              value={CurrentJobNumberDisplay}
            />
          </Grid>
          <Grid item md={6} xs={12} style={{ paddingTop: '24px' }}>
            <InputDate
              label="Inspection Date"
              name="InspectionDate_Date"
              onChange={onChange}
              firestore={firestore}
              value={CurrentInspectionDateDate}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Search
              label="Status"
              name="Status"
              searchfnc={FilterStatus}
              firestore={firestore}
              value={CurrentStatus}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )

}

export default InspectionsFilter;