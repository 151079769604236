/* eslint-disable react/no-unused-state */
import React from 'react';
import firebase from 'firebase';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import SubmitHelpDeskTicket from './SubmitHelpDeskTicket';
import * as serviceWorker from '../serviceWorkerRegistration';
import { functions, auth } from '../firebase';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null, data: null, fError: null };
    this.mounted = true;
    this.firebaseCall = this.firebaseCall.bind(this);
    this.sendTheMail = this.sendTheMail.bind(this);
  }

  componentDidCatch(e, ei) {
    // Catch errors in any components below and re-render with error message
    if (this.mounted) {
      this.setState({
        error: e,
        errorInfo: ei
      });
    }
    // You can also log error messages to an error reporting service here
    const { componentName } = this.props;
    this.sendTheMail(componentName, ei.componentStack, e);
    firebase.analytics().logEvent('page_render_error', { pageWithError: componentName, errorDetailInfo: ei.componentStack, errorLogged: e });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  firebaseCall(body) {
    const firebaseFunction = functions.httpsCallable("SendEmail");
    if (this.mounted)
      this.setState({ data: null, fError: null });
    const dataCall = (b) => {
      auth.currentUser
        .getIdToken(true)
        .then((idToken) => {
          const opts = {
            body: b,
            idToken,
            host: window.location.hostname
          };
          firebaseFunction(opts)
            .then((res) => {
              switch (res.data.status) {
                case 'Success':
                  if (this.mounted)
                    this.setState({ data: res.data.message });
                  break;
                case 'Error':
                  if (this.mounted)
                    this.setState({ fError: res.data.message });
                  break;
                default:
                  if (this.mounted)
                    this.setState({ fError: JSON.stringify(res) });
                  break;
              }
            })
            .catch((err) => {
              if (this.mounted)
                this.setState({ fError: err.message });
            });
        })
        .catch((err) => {
          if (this.mounted)
            this.setState({ fError: err.message });
        });
    };
    dataCall(body);
    return this.firebaseCall;
  }

  sendTheMail(cName, eStack, err) {
    const sendEmail = this.firebaseCall;
    sendEmail({ to: ['development@aldridgegroup.com'], subject: "Page Render Error - CARE", html: `<h1>${cName} in CARE has crashed :/</h1><h2>${err}</h2> <p>${eStack}</p>` });
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;
    const fRefreshApplication = () => {
      serviceWorker.unregister()
      window.location.reload();
    }
    if (error) {
      // Error path
      return (
        <div>
          <h1>Something went wrong.</h1>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            <br />
            {error && error.toString()}
            <h3 style={{ marginTop: '16px' }}>Keep seeing this? </h3>
            <div style={{ padding: '8px' }}><Button color='primary' variant='contained' onClick={fRefreshApplication}>
              Force Refresh Application
            </Button></div>
            <h4>Or submit a help desk ticket.</h4>
            <div style={{ padding: '8px' }}><SubmitHelpDeskTicket /></div>
          </details>
        </div>
      );
    }
    // Normally, just render children
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  componentName: PropTypes.string.isRequired
};

export default ErrorBoundary;