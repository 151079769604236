/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormHelperText
} from '@material-ui/core';
import Loader from 'react-loader-spinner';
import { toast } from 'react-toastify';
import { ExpandMore } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import firebase from 'firebase';
import {
  InputDate, InputText,
  InputRadio, InputRadioGroup,
  // eslint-disable-next-line no-unused-vars
  InputTextarea, InputAutocomplete, InputSelect
} from '@aldridge/aldg-data-components';
import { useRecoilValue } from 'recoil';
import { GreenButton, AccordionBlueClass, AccordionBlueDetailsClass, AccordionBlueSummaryClass, RedAsterisk } from '../providers/MuiTheme';
import { firestore } from '../firebase';
import { UserContext } from '../providers/UserProvider';
import { ObservationsList } from './Observations';
import { _AllInspectionTypes } from '../_Recoil/atoms';
import ErrorBoundary from '../_helpers/ErrorBoundary';

const Inspections = (props) => {
  const { match, history } = props;
  const [saving, setSaving] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);
  const [InspectionTypes, setInspectionTypes] = useState([]);
  const AllInspectionTypes = useRecoilValue(_AllInspectionTypes);
  const user = useContext(UserContext);
  const [record, setRecord] = useState({
    id: '',
    CreatedDate: '',
    CreatedBy: '',
    ModifiedBy: '',
    ModifiedDate: '',
    SharedWith: [],
    data: {
      JobNumber: [],
      Company: '',
      CompanyName: '',
      Inspector: user.name,
      Superintendent: [],
      InspectionDate: '',
      InspectionType: '',
      DescriptionOfWorkObserved: '',
      Comments: '',
      Recommendations: '',
      ReadyForObservations: false,
      Status: 'Open'
    }
  });
  const [Observations, SetObservations] = useState([]);
  let SafeObservations = [];

  useEffect(() => {
    if (loadingForm && record.id !== '') {
      setLoadingForm(false);
    }
  }, [record]);
  useEffect(() => {
    let mounted = true;
    if (match.params.id !== 'new') {
      if (mounted)
        setLoadingForm(true);
      firestore.collection('Inspections').doc(match.params.id).onSnapshot((snap) => {
        if (snap.exists) {
          const d = snap.data();
          if (mounted)
            setRecord(d);
        }
        else if (mounted) {
          setRecord({ ...record, id: snap.id });
          firebase.analytics().logEvent('inspection_created', { record_id: snap.id, user: user.name });
        }
      });
      firestore.collection(`Inspections/${match.params.id}/Observations`).onSnapshot((snap) => {
        const all = [];
        snap.forEach(doc => {
          all.push(doc.data());
        });
        if (mounted) {
          SetObservations(all);
          SafeObservations = all.filter((res) => res.data.ObservationType === 'Safe');
        }
      });
    }
    return () => { mounted = false; };
  }, [match]);

  const JobNumberDisabled = (record.data.Location === 'Job' && typeof record.data.JobNumber !== 'undefined' && record.data.JobNumber.length !== 0);
  const YardDisabled = (record.data.Location === 'Yard' && typeof record.data.Yard !== 'undefined' && record.data.Yard.length !== 0);
  const InspectionTypeDisabled = (typeof record.data.InspectionType !== 'undefined' && record.data.InspectionType !== '');
  const InspectionDateDisabled = (typeof record.data.InspectionDate !== 'undefined' && record.data.InspectionDate !== '');
  const shouldBeDisabled = (() => {
    let disabled = false;
    if (typeof match.params.id !== 'undefined' && match.params.id !== '') {
      if ((JobNumberDisabled || YardDisabled) && InspectionTypeDisabled && InspectionDateDisabled) {
        disabled = true;
      }
    }
    return disabled;
  }
  )();
  const disableForUsers = (record.data.Status === 'Complete' && !user.admin);
  const handleSubmit = (st = true) => {
    try {
      const updatedRecord = { ...record };
      if (match.params.id === 'new') {
        const RECORDID = firestore.collection('Inspections').doc().id;
        updatedRecord.id = RECORDID;
      }
      if ((JobNumberDisabled || YardDisabled) && InspectionTypeDisabled && InspectionDateDisabled)
        updatedRecord.data.ReadyForObservations = true;
      SafeObservations.forEach((so) => {
        const docRef = firestore.collection('Inspections').doc(updatedRecord.id).collection('Observations').doc();
        const nso = { ...so };
        nso.id = docRef.id;
        docRef.set(nso);
      });
      firestore
        .collection(`Inspections`)
        .doc(`${updatedRecord.id}`)
        .set(updatedRecord, { merge: true });
      setRecord(updatedRecord);
      console.log(match);
      if (match.params.id === 'new') history.push(`/Inspections/${updatedRecord.id}`);
      if (st) toast.success('Inspection Saved Successfully.', { toastId: 'handleSubmitToastSuccess', autoClose: 5000 });
    } catch (err) {
      toast.error("Error occured while saving form.", { toastId: 'handleSubmitToastErr', autoClose: 5000 });
    }
  };
  const handleComplete = () => {
    setSaving(true);
    firestore.collection(`Inspections/${record.id}/Observations`).get().then((obs) => {
      const allObs = [];
      const errs = [];
      if (record.data.Location === 'Job' && record.data.JobNumber.length === 0) errs.push('Job Number is Required');
      if (record.data.Location === 'Yard' && record.data.Yard.length === 0) errs.push('Yard is Required');
      if (record.data.Superintendent.length === 0) errs.push('Superintendent is Required');
      if (record.data.InspectionDate === '') errs.push('Inspection Date is Required');
      if (record.data.InspectionType === '') errs.push('Inspection Type is Required');
      if (record.data.DescriptionOfWorkObserved === '') errs.push('Description of Work Observed is Required');
      if (errs.length > 0) {
        toast.error(`The following fields need to be filled out before completing the Inspection: ${errs.join(', ')}.`, { toastId: 'handleCompleteErrors' });
        return;
      }
      if (!obs.empty) {
        obs.forEach((d) => allObs.push(d.data()));
        const anyOpens = allObs.filter((res) => res.data.Status === 'Open');
        if (anyOpens.length > 0) {
          toast.error('You must close out any open Observations before completing the Inspection.', { toastId: 'handleCompleteErrorsOpenObs' });
          return;
        }

        const colRef = firestore.collection('Inspections');
        record.data.Status = 'Complete';
        record.data.CompletedDate = new Date().toJSON();
        colRef.doc(record.id).set(record, { merge: true });
        setSaving(false);
        firebase.analytics().logEvent('inspection_completed', { record_id: record.id, user: user.name });

        toast.success('Inspection Completed Successfully.', { toastId: 'handleCompleteSuccess', autoClose: 5000 });
      }
      else if (SafeObservations.length > 0) {
        SafeObservations.forEach((so) => {
          const docRef = firestore.collection('Inspections').doc(record.id).collection('Observations').doc();
          const nso = { ...so };
          nso.id = docRef.id;
          nso.data.InspectionStatus = "Complete";
          docRef.set(nso);
        });
      }
      else {
        toast.error('Please enter any relevant Observations before completing the Inspection.', { toastId: 'handleCompleteNoObs' });
      }
    });

    const colRef = firestore.collection('Inspections');
    colRef.doc(record.id).set(record, { merge: true });
    setSaving(false);
  };
  const handleChange = (event, name, displayFromTypeahead) => {
    try {
      const id = name || event.target.name;
      const value = typeof name !== 'undefined' ? event : event.target.value;
      const changeRecord = { ...record };
      changeRecord.data[id] = value;
      if (typeof displayFromTypeahead !== 'undefined') {
        changeRecord.data[`${id}Display`] = displayFromTypeahead;
      }
      if (id === 'InspectionDate') {
        const [date, time] = event.target.value.split('T');
        changeRecord.data[`${id}_Date`] = date;
        changeRecord.data[`${id}_Time`] = time;
      }

      if (id === 'Company') {
        changeRecord.data[id] = value;
        changeRecord.data.CompanyName = value === 'Subcontractor' ? [] : '';
      }
      if (changeRecord.CreatedBy === '') {
        changeRecord.CreatedBy = user.name;
        changeRecord.CreatedDate = new Date().toJSON();
      }
      changeRecord.ModifiedBy = user.email;
      changeRecord.ModifiedDate = new Date().toJSON();
      setRecord(changeRecord);
    }
    catch (err) {
      toast.error(err.message);
    }
  };

  useEffect(() => {
    let mounted = true;
    firestore.collection('Employees').where('Email', '==', user.email).get().then((snap) => {
      const allInspectionTypeOptions = [];
      if (!snap.empty) {
        const doc = snap.docs[0].data();
        const its = AllInspectionTypes.filter(it => doc.InspectionTypes.indexOf(it.id) > -1);

        its.forEach(it => {
          const option = JSON.parse(JSON.stringify(it));
          option.label = option.data.InspectionType;
          option.value = option.data.InspectionType;
          option.categories = option.data.Categories;
          option.key = option.id;
          allInspectionTypeOptions.push(option);
        });
        if (mounted)
          setInspectionTypes(allInspectionTypeOptions);
      } else if (mounted)
        setInspectionTypes([]);
    });
    return () => { mounted = false; };
  }, [AllInspectionTypes]);

  const ObservationsOptions = () => {
    const categories = AllInspectionTypes.filter(res => res.data.InspectionType === record.data.InspectionType).map(cat => cat.data.Categories);
    if (categories.length > 0) {
      const o = [];
      for (let i = 0; i < categories[0].length; i++) {
        o.push(<ObservationsList
          InspectionSubmit={handleSubmit}
          Inspection={record}
          Category={categories[0][i]}
          Observations={Observations}
          SafeObservations={SafeObservations}
          key={categories[0][i].id}
          history={history}
        />);
      }
      return o;
    }
    return <div style={{ width: '100%', justifyContent: 'center', display: 'flex' }}   >  <Loader
      type="TailSpin"
      color="#3b78e7"
      height={100}
      width={100}
    /></div>;
  };

  const greenButton = GreenButton();
  const accordionClass = AccordionBlueClass();
  const accordionSummaryClass = AccordionBlueSummaryClass();
  const accordionDetailsClass = AccordionBlueDetailsClass();
  const hasOpenObservation = (() => {
    let hasOpen = false;
    if (typeof window.currentCategory !== 'undefined' && window.currentCategory !== '') {
      hasOpen = true;
    }
    return hasOpen;
  })();
  return (
    <ErrorBoundary componentName="Inspections">
      {loadingForm ? (<div style={{
        display: 'block', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: '#efefef', opacity: 0.5, zIndex: 2000
      }}>

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
          <div style={{ textAlign: 'center' }}>
            <Loader
              type="TailSpin"
              color="#3b78e7"
              height={100}
              width={100}
            />
            Loading Record...
          </div>
        </div>
      </div>)

        : null}
      {
        saving ? (
          <div style={{
            display: 'block', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: '#efefef', opacity: 0.5, zIndex: 100
          }}
          />
        ) : <></>
      }
      <div style={{
        position: 'sticky', top: 0, left: 0, right: 0, width: '100%', backgroundColor: 'white', paddingTop: '10px', paddingBottom: '5px', paddingLeft: '0px', display: 'flex', justifyContent: 'flex-end', zIndex: 100,
      }}
      >
        <Button variant="contained" color="primary" onClick={handleSubmit} disabled={disableForUsers}>Save</Button>
        <Button variant="contained" color="primary" className={greenButton.root} style={{ marginLeft: '5px' }} onClick={handleComplete} disabled={disableForUsers}>Complete</Button>
        <Link style={{ textDecoration: 'none' }} to="/Inspections"><Button variant="contained" style={{ marginLeft: '5px', color: "black" }}>Close</Button></Link>
      </div>

      <Grid container spacing={0}>
        <Grid item xs={12} style={{ marginTop: '8px' }}>
          <Accordion padding='0px' defaultExpanded classes={accordionClass}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              classes={accordionSummaryClass}
            >
              General Information
            </AccordionSummary>
            <AccordionDetails classes={accordionDetailsClass}>
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <Grid container spacing={2} direction="column">
                    <Grid item xs={12}>
                      <InputRadioGroup name="Location" value={record.data.Location || ''} label="Location" onChange={handleChange} disabled={disableForUsers} >
                        <InputRadio value="Job" label="Job" />
                        <InputRadio value="Yard" label="Yard" />
                      </InputRadioGroup>
                    </Grid>
                    {
                      record.data.Location === 'Job' ?
                        <Grid item xs={12}>
                          <InputAutocomplete
                            name="JobNumber"
                            label="Job Number"
                            onChange={handleChange}
                            value={record.data.JobNumber || []}
                            optionDisplay={['option_JobNumber', '.', 'option_SubJobNumber', ' - ', 'option_JobDescription']}
                            valueKey="jctdscid"
                            orderBy="JobNumber"
                            firestore={firestore}
                            collection="Jobs"
                            where={[['JobStatus', '==', 'Open']]}
                            required
                            customTheme={RedAsterisk}
                            disabled={shouldBeDisabled && (record.data.ReadyForObservations) && disableForUsers}
                          />
                        </Grid> : null}
                    {
                      record.data.Location === 'Yard' ?
                        <Grid item xs={12}>
                          <InputAutocomplete
                            name="Yard"
                            label="Yard"
                            onChange={handleChange}
                            value={record.data.Yard || []}
                            optionDisplay={(opts) => {
                              let display = '';
                              if (Object.keys(opts).length > 0) {
                                display = `${opts.data.Name}${opts.data.City !== '' ? ` - ${opts.data.City}` : ''}${opts.data.State !== '' && opts.data.City !== '' ? `, ${opts.data.State}` : ''}${opts.data.City === '' && opts.data.State !== '' ? ` - ${opts.data.State}` : ''}`;
                              }
                              return display;
                            }}
                            valueKey="id"
                            orderBy="data.Name"
                            firestore={firestore}
                            collection="Yards"
                            where={[['data.Status', '==', 'Active']]}
                            required
                            enterprise={false}
                            customTheme={RedAsterisk}
                            disabled={shouldBeDisabled && (record.data.ReadyForObservations) && disableForUsers}
                            emptyPlaceholder="[No Yards have been added. Contact a CARE Admin to add a Yard...]"
                          />
                        </Grid> : null
                    }
                  </Grid>
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputRadioGroup name="Company" value={record.data.Company || ''} label="Company" onChange={handleChange} disabled={disableForUsers}>
                    <InputRadio value="Aldridge" label="Aldridge" style={{ transform: 'scale(1.25)' }} />
                    <InputRadio value="Subcontractor" label="Subcontractor" style={{ transform: 'scale(1.25)' }} />
                    <InputRadio value="3rd Party" label="3rd Party" style={{ transform: 'scale(1.25)' }} />
                  </InputRadioGroup>
                  {
                    record.data.Company === 'Subcontractor'
                      ? (
                        <InputAutocomplete
                          name="CompanyName"
                          label="Company Name"
                          onChange={handleChange}
                          value={record.data.CompanyName || []}
                          optionDisplay={(opts) => {
                            if (Object.keys(opts).length === 0) return '';
                            return `${opts.VendorName} (Contract #: ${opts.ContractNumber})`;
                          }}
                          valueKey="id"
                          firestore={firestore}
                          customTheme={RedAsterisk}
                          orderBy="VendorName"
                          collection="SubcontractorVendors"
                          where={[['Type', '==', `${record.data.Company}`], ['JCTDSCID', '==', `${record.data.JobNumber[0]}`]]}
                          requirement={record.data.Company}
                          hidden={record.data.Company !== 'Subcontractor'}
                          disabled={disableForUsers}
                          emptyPlaceholder={`${record.data.Location === 'Yard' ? 'There are no Subcontractors associated to a Yard' : `There are no Subcontractors associated to Job ${record.data.JobNumberDisplay}`}`}
                          required
                        />
                      )
                      : <InputText label="3rd Party" name="CompanyName" onChange={handleChange} value={record.data.CompanyName || ''} hidden={!(record.data.Company === '3rd Party')} required disabled={disableForUsers} />
                  }
                </Grid>

                <Grid item md={6} xs={12} style={{ display: 'flex', alignItems: 'flex-end' }}>
                  <InputText name="Inspector" value={record.data.Inspector === '' ? user.name : record.data.Inspector} label="Inspector" disabled />
                </Grid>
                <Grid item md={6} xs={12} >
                  <InputAutocomplete
                    name="Superintendent"
                    label="Superintendent"
                    onChange={handleChange}
                    value={record.data.Superintendent || []}
                    optionDisplay={['option_LegalFirstName', ' ', 'option_LegalLastName']}
                    valueKey="prtmstid"
                    customTheme={RedAsterisk}
                    firestore={firestore}
                    orderBy="LegalLastName"
                    collection="Employees"
                    where={[['Status', '==', 'A']]}
                    disabled={disableForUsers}
                    required
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <InputDate type="datetime-local" label="Inspection Date" name="InspectionDate" disabled={shouldBeDisabled && (record.data.ReadyForObservations) && disableForUsers} customTheme={RedAsterisk} onChange={handleChange} value={record.data.InspectionDate || ''} required />
                </Grid>
                <Grid item md={6} xs={12}>
                  {
                    AllInspectionTypes.length > 0 ?
                      <InputSelect
                        label="Inspection Type"
                        name="InspectionType"
                        onChange={handleChange}
                        value={(record.data.InspectionType || '')}
                        options={InspectionTypes.length === 0 ? [{ label: record.data.InspectionType, value: record.data.InspectionType, key: record.id }] : InspectionTypes}
                        disabled={(shouldBeDisabled && (record.data.ReadyForObservations) && disableForUsers) || InspectionTypes.length === 0}
                        customTheme={RedAsterisk}
                        required
                        shrink
                      />
                      : <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}><Loader type="TailSpin"
                        color="#3b78e7"
                        height={30}
                        width={30}
                      />&nbsp;&nbsp;Loading Inspection Types...
                      </div>
                  }

                </Grid>
                <Grid item xs={12}>
                  <InputTextarea label="Description of Work Observed" name="DescriptionOfWorkObserved" onChange={handleChange} value={record.data.DescriptionOfWorkObserved} customTheme={RedAsterisk} disabled={disableForUsers} required />
                </Grid>
                <Grid item xs={12}>
                  <InputTextarea label="Comments" name="Comments" onChange={handleChange} value={record.data.Comments} disabled={disableForUsers} />
                </Grid>
                <Grid item xs={12}>
                  <InputTextarea label="Recommendations" name="Recommendations" onChange={handleChange} value={record.data.Recommendations} disabled={disableForUsers} />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid container style={{ padding: '5px 0' }} />
        {
          (shouldBeDisabled && record.data.ReadyForObservations)
            ? (
              <Grid item xs={12}>
                {/* <Accordion classes={accordionClass}> */}
                <Accordion classes={accordionClass} defaultExpanded={hasOpenObservation}>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    classes={accordionSummaryClass}
                  >Observations
                  </AccordionSummary>
                  <AccordionDetails classes={accordionDetailsClass}>
                    <Grid container>
                      <Grid item xs={12}>
                        <FormHelperText style={{ textAlign: 'center', fontSize: '0.85rem', paddingBottom: '10px', color: 'rgba(0, 0, 0, 0.75)' }}>
                          The &quot;Safe&quot; and &quot;At-Risk&quot; counts show only synced records in the database. Please save your Inspection to see all new and updated values.
                        </FormHelperText>
                      </Grid>
                      {ObservationsOptions() ? ObservationsOptions() : []}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            )
            : <><h4>Please Complete the General Info section and save to continue...</h4></>
        }
      </Grid>

    </ErrorBoundary >
  );
};

Inspections.propTypes = {
  history: PropTypes.objectOf(PropTypes.any),
  match: PropTypes.objectOf(PropTypes.any),
};
Inspections.defaultProps = {
  history: {},
  match: {},
};

export default Inspections;
