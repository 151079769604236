/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Table, TableRow, TableCell, TableBody, Button } from '@material-ui/core';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { firestore } from '../../../firebase';
import { icons, basicStyles } from '../../../theme';

const ChangelogList = (props) => {
  const { history } = props;
  const [reports, setReports] = useState([]);

  useEffect(() => {
    let mounted = true;
    firestore
      .collection('Changelog')
      .orderBy('Version', 'desc')
      .onSnapshot(snap => {
        const docs = [];
        if (!snap.empty) {
          snap.forEach(doc => {
            docs.push(doc.data());
          });
        }
        if (mounted)
          setReports(docs);
      });
    return () => (mounted = false);
  }, []);

  const [deleteState, setDeleteState] = useState({
    proceedLabel: 'Delete',
    cancelLabel: 'Cancel',
    title: 'Delete Record',
    modalBody: 'Are you sure you want to remove this Record?',
    show: false,
    // eslint-disable-next-line no-use-before-define
    proceed: (approved, r) => { removeRecord(approved, r); },
    enableEscape: true
  });

  const addNew = () => {
    history.push(`/changelog/new`);
  };

  const removeRecord = async (a, r) => {
    // if (a) {
    //   if (r.idx !== '') {
    await firestore.collection(`Changelog`).doc(r.idx).delete().then(() => {

      toast.success('Daily Report Removed.');
    });
    // }
    // }
    // setDeleteState({ ...deleteState, show: false });

  };
  const classes = basicStyles();
  const iconClasses = icons();
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: '8px',
          marginTop: '8px'
        }}
      >
        <div className={`${iconClasses.container} ${iconClasses.addContainer}`} style={{ marginRight: '0' }}>
          <Button onClick={addNew} disableRipple className={iconClasses.buttonWrapper}>
            <FontAwesomeIcon icon={faPlus} className={iconClasses.icon} onClick={addNew} title="Add Changelog" />
          </Button>
        </div>
      </div>
      {/* <ConfirmDelete
        proceedLabel={deleteState.proceedLabel}
        cancelLabel={deleteState.cancelLabel}
        title={deleteState.title}
        modalBody={deleteState.modalBody}
        show={deleteState.show}
        proceed={deleteState.proceed}
        enableEscape={deleteState.enableEscape}
        record={deleteState.record}
      /> */}
      <Table>
        <TableBody>
          <TableRow style={{ backgroundColor: 'white' }}>
            <TableCell className={`${classes.tableHeader} ${classes.tableCell}`}>Version</TableCell>
            <TableCell className={`${classes.tableHeader} ${classes.tableCell}`}>Deploy Date</TableCell>

          </TableRow>
          {reports.map(r => (<TableRow className={classes.row} key={`${r.id}`}>
            <TableCell className={classes.tableCell} onClick={() => history.push(`changelog/${r.id}`)}>{r.Version}</TableCell>
            <TableCell className={classes.tableCell} onClick={() => history.push(`changelog/${r.id}`)}>{r.DeployDate}</TableCell>
          </TableRow>))}
        </TableBody>
      </Table>
    </>
  );
};

ChangelogList.propTypes = {
  // match: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any)
};
ChangelogList.defaultProps = {
  // match: {
  //   params: {}
  // },
  history: {
    push: () => { }
  }
};
export default ChangelogList;