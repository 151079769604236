/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import {
  FormLabel, ThemeProvider, FormControl, FormControlLabel, Radio, RadioGroup, withStyles
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { RedAsterisk } from '../../providers/MuiTheme';

const InputRadioGroup = (props) => {
  const {
    name, onChange, children, invalid, selectedValue, disabled, label, fullWidth, hidden, required
  } = props;
  return (
    <>
      <ThemeProvider theme={RedAsterisk}>
        <FormControl fullWidth={fullWidth} hidden={hidden} required={required}>
          <FormLabel>{label}</FormLabel>
          <RadioGroup name={name} value={selectedValue} onChange={(e) => onChange(e)}>
            {
          children.length > 0
            ? children.map((c) => (
              React.cloneElement(c, {
                onChange, name, invalid, key: `${name}_${c.props.value}`, selectedValue, disabled
              })
            ))
            : <></>
          }
          </RadioGroup>
        </FormControl>
      </ThemeProvider>
    </>
  );
};

const InvalidRadio = withStyles({
  root: {
    color: red[400],
    '&$checked': {
      color: red[600],
    }
  },
  checked: {},
})((props) => <Radio color="secondary" {...props} />);
const InputRadio = (props) => {
  const {
    label, name, value, invalid, style, hidden, disabled
  } = props;
  return (
    <FormControlLabel style={{ fontWeight: '400' }} value={value} label={label} disabled={disabled} hidden={hidden} control={invalid ? <InvalidRadio name={name} style={style} /> : <Radio name={name} style={style} color="primary" />} />
  );
};

InputRadio.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  invalid: PropTypes.bool,
  style: PropTypes.objectOf(PropTypes.any),
  hidden: PropTypes.bool,
  disabled: PropTypes.bool,
};
InputRadio.defaultProps = {
  label: '',
  name: '',
  value: '',
  invalid: false,
  style: {},
  hidden: false,
  disabled: false,
};

InputRadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  invalid: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  selectedValue: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  hidden: PropTypes.bool,
  fullWidth: PropTypes.bool,
  required: PropTypes.bool
};
InputRadioGroup.defaultProps = {
  invalid: false,
  disabled: false,
  label: '',
  hidden: false,
  fullWidth: true,
  required: false
};

export {
  InputRadio, InputRadioGroup
};
