import React, { useContext, createContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { firestore } from '../firebase';
import { useInitializeOffline } from '../_GlobalComponents/InitializeOffline';
import { _InitedOffline, _AllCategories, _AllSubcategories, _AllInspectionTypes, _AllYards } from '../_Recoil/atoms';
import { UserContext } from './UserProvider';

export const OfflineDataContext = createContext({ user: null });
const OfflineDataProvider = (props) => {
  const user = useContext(UserContext);
  const { syncs, addSync, syncData, setInitialCount, setFirestore } = useInitializeOffline();
  const { children } = props;
  const [InitedOffline, setInitedOffline] = useRecoilState(_InitedOffline);
  const setAllCategories = useSetRecoilState(_AllCategories);
  const setAllYards = useSetRecoilState(_AllYards);
  const setAllSubcategories = useSetRecoilState(_AllSubcategories);
  const setAllInspectionTypes = useSetRecoilState(_AllInspectionTypes);

  useEffect(() => {
    if (user) {
      setFirestore(firestore);
      addSync('Categories', { query: firestore.collection('Categories'), storeInAtom: true, atomSetter: setAllCategories }, 'Categories');
      addSync('Yards', { query: firestore.collection('Yards'), storeInAtom: true, atomSetter: setAllYards }, 'Yards');
      addSync('Employees', { query: firestore.collection('Employees').where('Status', '==', 'A') }, 'Employees');
      addSync('InspectionTypes', { query: firestore.collection('InspectionTypes'), storeInAtom: true, atomSetter: setAllInspectionTypes }, 'Inspection Types');
      // addSync('Inspections', { query: firestore.collection('Inspections').where('data.Inspector', '==', user.name) }, 'Inspections');
      // addSync('Observations', { query: firestore.collection('Inspections').where('data.Inspector', '==', user.name), getSub: true, subcollection: 'Observations' }, 'Observations');
      addSync('Jobs', { query: firestore.collection('Jobs').where('JobStatus', '==', 'Open') }, 'Jobs');
      addSync('Subcategories', { query: firestore.collection('Subcategories'), storeInAtom: true, atomSetter: setAllSubcategories }, 'Subcategories');
      // addSync('SubcontractorVendors', { query: firestore.collection('SubcontractorVendors').where('Status', '=', 'A') }, 'Subcontractors/Vendors');
      if (!InitedOffline.value) {
        setInitialCount(6);
        setInitedOffline({ value: true, lastInited: new Date().toJSON() });
      } else {
        firestore.collection('Categories').get({ source: 'cache' }).then(res => {
          if (!res.empty) {
            const docs = [];
            res.forEach(doc => docs.push(doc.data()));
            setAllCategories(docs);
            // eslint-disable-next-line
            // console.log("Categories- cache");
          } else {
            firestore.collection('Categories').get({ source: 'server' }).then(res2 => {
              if (!res2.empty) {
                const docs = [];
                res2.forEach(doc => docs.push(doc.data()));
                setAllCategories(docs);
                // eslint-disable-next-line
                // console.log("Categories- server");
              } else {
                // eslint-disable-next-line
                console.log("No data found for Categories!");
              }
            });
          }
        });

        firestore.collection('Yards').get({ source: 'cache' }).then(res => {
          if (!res.empty) {
            const docs = [];
            res.forEach(doc => docs.push(doc.data()));
            setAllYards(docs);
            // eslint-disable-next-line
            // console.log("Yards- cache");
          } else {
            firestore.collection('Yards').get({ source: 'server' }).then(res2 => {
              if (!res2.empty) {
                const docs = [];
                res2.forEach(doc => docs.push(doc.data()));
                setAllYards(docs);
                // eslint-disable-next-line
                // console.log("Yards- server");
              } else {
                // eslint-disable-next-line
                console.log("No data found for Yards!");
              }
            });
          }
        });
        firestore.collection('InspectionTypes').get({ source: 'cache' }).then(res => {
          if (!res.empty) {
            const docs = [];
            res.forEach(doc => docs.push(doc.data()));
            setAllInspectionTypes(docs);
            // eslint-disable-next-line
            // console.log("Inspection Types- cache");
          } else {
            firestore.collection('InspectionTypes').get({ source: 'server' }).then(res2 => {
              if (!res2.empty) {
                const docs = [];
                res2.forEach(doc => docs.push(doc.data()));
                setAllInspectionTypes(docs);
                // eslint-disable-next-line
                // console.log("Inspection Types- server");
              } else {
                // eslint-disable-next-line
                console.log("No data found for Inspection Types!");
              }
            });
          }
        });
        firestore.collection('Subcategories').get({ source: 'cache' }).then(res => {
          if (!res.empty) {
            const docs = [];
            res.forEach(doc => docs.push(doc.data()));
            setAllSubcategories(docs);
            // eslint-disable-next-line
            // console.log("Subcategories- cache");
          } else {
            firestore.collection('Subcategories').get({ source: 'server' }).then(res2 => {
              if (!res2.empty) {
                const docs = [];
                res2.forEach(doc => docs.push(doc.data()));
                setAllSubcategories(docs);
                // eslint-disable-next-line
                // console.log("Subcategories- sever");
              } else {
                // eslint-disable-next-line
                console.log("No data found for Subcategories!");
              }
            });
          }
        });
      }
    }
  }, [user, InitedOffline]);

  return <OfflineDataContext.Provider value={{ syncs, addSync, syncData, setInitialCount }}>{children}</OfflineDataContext.Provider>;
};

OfflineDataProvider.propTypes = {
  children: PropTypes.objectOf(PropTypes.any),
};
OfflineDataProvider.defaultProps = {
  children: [],
};
export default OfflineDataProvider;
