/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import React, { useState, useContext, useEffect } from 'react';
import { Drawer, BottomNavigationAction, BottomNavigation, useTheme, useMediaQuery, Button } from '@material-ui/core';
import { faCopyright, faBars, faUserCog } from '@fortawesome/pro-regular-svg-icons';
import { Link, useLocation } from 'react-router-dom';
import { ModalBox, useModalState } from '@aldridge/aldg-confirm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSetRecoilState } from 'recoil';
import { basicStyles } from '../Pages/theme';
import { MenuData } from './Menu/MenuData';
import { UserContext } from '../providers/UserProvider';
import DashboardSidebar from './DashboardSidebar';
import NavItem from './NavItem';
import './footer.css';
import ErrorBoundary from '../_helpers/ErrorBoundary';
import { _ShowChangelogModal } from '../_Recoil/atoms';

const ConfidentialityModal = () => {
  const [modal1, setModalOpen] = useModalState({
    open: false,
    title: 'CONFIDENTIALITY NOTICE:',
    message: 'Unless otherwise indicated or obvious from the nature of the application, the information contained in this application is attorney privileged and confidential information intended for the use of authorized individuals or entities only. If the viewer of this content is not correctly authorized, you are hereby notified that any dissemination, distribution or copying of this content is strictly prohibited.',
    record: 'goodbyehello',
    buttons: [
      {
        text: 'Close',
        onClick: (record, { close }) => {
          close();
        },
      },
    ],
    onClose: ({ close }) => {
      close();
    },
  });
  return (
    <>
      <ModalBox modal={modal1} />
      <button type="button" className="button" onClick={() => setModalOpen(true)}>
        <i>Click here to review the confidentiality statement.</i>
      </button>
    </>
  );
};

const Footer = (props) => {
  const { version, copy } = props;
  const [footerOpen, setFooterOpen] = useState(false);
  const match = useLocation();
  const [opening, setOpening] = useState(0);
  const [closing, setClosing] = useState(0);
  const [value, setValue] = React.useState(0);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const matches2 = useMediaQuery(theme.breakpoints.down('xs'));
  const SetShowChangelogModal = useSetRecoilState(_ShowChangelogModal);
  const user = useContext(UserContext);
  const [drawer, setDrawer] = React.useState(false);
  const [adminDrawer, setAdminDrawer] = React.useState(false);
  const [keyboardHide, setKeyboardHide] = React.useState(false);
  const pastHeight = window.innerHeight;

  const onClick = () => {
    setFooterOpen(!footerOpen);
    if (footerOpen && !matches)
      setOpening(1);
    else if (!matches)
      setClosing(1);
  };
  const findBottom = () => {
    if (!matches && !footerOpen)
      return '10px';
    return '65px';
  };

  useEffect(() => {
    const handleKeyboard = () => {
      if (matches2) {
        const currentHeight = window.innerHeight;
        if (pastHeight > currentHeight) { setKeyboardHide(true); } else { setKeyboardHide(false); };
      }
    };
    window.addEventListener('resize', handleKeyboard);
  });

  useEffect(() => {
    const first = match.pathname.split('/')[1];
    const last = match.pathname.split('/')[(match.pathname.split('/').length - 1)];
    switch (first) {
      case '':
      case 'Inspections':
        switch (last) {
          case 'new':
            setValue(1);
            break;
          default:
            setValue(0);
            break;
        }
        break;
      default:
        setValue(6);
        break;
    }
  }, [match]);

  const onLinkClick = () => {
    localStorage.removeItem('ChangelogShown');
    SetShowChangelogModal(true);
  };

  const classes = basicStyles();
  return (
    <ErrorBoundary componentName="Footer">
      {user && !drawer && !adminDrawer && !keyboardHide ? <Button className="icon" style={{
        position: 'fixed',
        bottom: findBottom(),
        right: matches2 ? '5px' : '15px',
        zIndex: '1000',
        backgroundColor: 'transparent',
        maxHeight: '25px',
        minWidth: '25px',
        maxWidth: '25px'
      }} onClick={onClick}
        onAnimationEnd={() => { setOpening(0); setClosing(0); }}
        opening={opening}
        closing={closing}>
        {
          footerOpen ? <FontAwesomeIcon justifycontent='right' style={{ color: 'black' }} icon={faCopyright} /> : <FontAwesomeIcon style={{ color: 'black' }} justifycontent='right' icon={faCopyright} />}</Button> : null}
      {matches && user && !keyboardHide ?
        <BottomNavigation position='relative' style={{ width: '100%', backgroundColor: 'rgb(52, 58, 64)' }} value={value} hidden={keyboardHide} showLabels>
          {MenuData.map(item => item.admin === false ? (
            <BottomNavigationAction
              label={item.title}
              icon={<FontAwesomeIcon className={`${classes.icon} fa-fw`} icon={item.icon} />}
              className='MuiBottomNavigationAction'
              component={Link}
              to={item.href}
              key={item.href}
            />
          ) : null)}
          {user.admin ? <BottomNavigationAction
            label='Admin'
            icon={<FontAwesomeIcon className={`${classes.icon} fa-fw`} icon={faUserCog} />}
            className='MuiBottomNavigationAction'
            onClick={() => { setAdminDrawer(true); }}
            key='Tool'
          /> : null}


          <BottomNavigationAction
            label='More'
            icon={<FontAwesomeIcon className={`${classes.icon} fa-fw`} icon={faBars} />}
            className='MuiBottomNavigationActionNoSelect'
            onClick={() => { setDrawer(true); }}
            key='More'
          />
        </BottomNavigation> : <></>}
      <Drawer variant='persistent' anchor='bottom' open={footerOpen} key='realFooter' onClose={() => setFooterOpen(false)}>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          alignItems: 'center',
          backgroundColor: '#343a40',
          padding: '0.25rem',
          color: '#fff',
          fontFamily: 'sans-serif', lineHeight: '1.2'
        }}
        >
          <div>Copyright &copy; {copy}</div>
          <ConfidentialityModal />
          <div>v {version}
            <button type="button" className="button" onClick={onLinkClick}> <u> Update Notes</u></button>
          </div>
        </div>
      </Drawer>
      <Drawer
        anchor='right'
        onClose={() => setDrawer(false)}
        open={drawer}
        variant='temporary'
        key='MoreDrawer'
        PaperProps={{
          sx: {
            width: 256
          }
        }}
      >
        <DashboardSidebar />
      </Drawer>
      <Drawer anchor='bottom'
        onClose={() => setAdminDrawer(false)}
        open={adminDrawer}
        variant='temporary'
        key='AdminDrawer'
        PaperProps={{
          sx: {
            width: 256
          }
        }}>
        {MenuData.map(item => item.admin === true ? (
          <div style={{ paddingLeft: '8px' }} key={item.title}>
            <NavItem
              href={item.href}
              title={item.title}
              icon={item.icon} />
          </div>
        ) : null)}
      </Drawer>
    </ErrorBoundary>
  );
};

Footer.propTypes = {
  version: PropTypes.string,
  copy: PropTypes.string
};
Footer.defaultProps = {
  version: '0.0.0',
  copy: 'Aldridge, All Rights Reserved'
};

export default Footer;
