/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Button,
  FormHelperText,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { DataGrid } from '@material-ui/data-grid';
import { toast } from 'react-toastify';
import { Delete } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import {
  InputText,
  InputRadio, InputRadioGroup
} from '@aldridge/aldg-data-components';
import { useRecoilState, useSetRecoilState } from 'recoil';
import Confirm from '../_GlobalComponents/Confirm';
import { firestore } from '../firebase';
import { UserContext } from '../providers/UserProvider';
import { _AllYards } from '../_Recoil/atoms';
import { basicStyles } from './theme';
import ErrorBoundary from '../_helpers/ErrorBoundary';

const YardsList = (props) => {
  const { history } = props;
  const [setups, setSetups] = useState([]);
  const [AllYards] = useRecoilState(_AllYards);
  const screenSizeWarning = () => <Alert severity="info">
    <AlertTitle>Attention</AlertTitle>
    Please use a tablet or computer to properly access this page!
  </Alert>;

  useEffect(() => {
    let mounted = true;
    console.log(AllYards);
    if (mounted)
      setSetups(AllYards);
    return () => { mounted = false; };
  }, [AllYards]);
  const onSelect = (id) => {
    history.push(`/Yards/${id}`);
  };
  const [deleteState, setDeleteState] = useState({
    proceedLabel: 'Delete',
    cancelLabel: 'Cancel',
    title: 'Delete Yard',
    modalBody: 'Are you sure you want to remove this Yard? Removing it will break some Inspections.',
    show: false,
    // eslint-disable-next-line no-use-before-define
    proceed: (approved, r) => { removeRecord(approved, r); },
    enableEscape: true
  });
  const removeRecord = (a, r) => {
    if (a) {
      if (r.idx !== '') {
        firestore.collection('Yards').doc(r.idx).delete();
      }
    }
    setDeleteState({ ...deleteState, show: false });
  };
  const handleDelete = (idx) => {
    setDeleteState({ ...deleteState, show: true, record: { idx } });
  };

  const classes = basicStyles();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));

  const getFlex = (f) => (matches ? null : f);

  const getWidth = (w) => (matches ? w : null);

  const columns = [
    {
      field: 'Name', headerName: 'Yard',
      valueGetter: (params) => params.row.data.Name,
      valueFormatter: (params) => params.row.data.Name, flex: getFlex(1),
      width: getWidth(400)
    },
    {
      field: 'Status', headerName: 'Status',
      valueGetter: (params) => params.row.data.Status,
      valueFormatter: (params) => params.row.data.Status, flex: getFlex(1),
      width: getWidth(200)
    },
    {
      field: 'City', headerName: 'City',
      valueGetter: (params) => params.row.data.City,
      valueFormatter: (params) => params.row.data.City, flex: getFlex(.6),
      width: getWidth(200)
    },
    {
      field: 'State', headerName: 'State',
      valueGetter: (params) => params.row.data.State,
      valueFormatter: (params) => params.row.data.State, flex: getFlex(.6),
      width: getWidth(200)
    },
    {
      field: 'Delete', headerName: 'Delete', sortable: false, filterable: false, flex: getFlex(.2), width: getWidth(100), align: 'right', renderCell: (params) =>
        <div><Delete style={{ cursor: 'pointer' }} onClick={(e) => { e.stopPropagation(); handleDelete(params.row.id); }} /></div>
    }
  ];
  return (
    <ErrorBoundary componentName="Yards List">
      {matches ? screenSizeWarning() : null}

      <Confirm
        proceedLabel={deleteState.proceedLabel}
        cancelLabel={deleteState.cancelLabel}
        title={deleteState.title}
        modalBody={deleteState.modalBody}
        show={deleteState.show}
        proceed={deleteState.proceed}
        enableEscape={deleteState.enableEscape}
        record={deleteState.record}
      />
      <div style={{
        position: 'sticky', top: 0, left: 0, right: 0, backgroundColor: 'white', width: '100%', display: 'flex', justifyContent: 'flex-end'
      }}
      >
        <Link style={{ margin: '16px' }} to="Yards/new"><Button variant="contained" color="primary">New Yard</Button></Link>
      </div>
      <div style={{
        width: '100%', display: 'flex', justifyContent: 'flex-end'
      }}
      />
      <div style={{ height: '100%' }}>
        <DataGrid
          className={classes.catRoot}
          getRowId={(row) => row.id}
          rows={setups}
          columns={columns}
          density={matches ? 'compact' : 'standard'}
          filterModel={{
            items: [
              { columnField: 'Name', operatorValue: 'contains', value: '' },
              { columnField: 'Status', operatorValue: 'contains', value: '' },
              { columnField: 'City', operatorValue: 'contains', value: '' },
              { columnField: 'State', operatorValue: 'contains', value: '' },

            ],
          }}
          onRowClick={(a) => { onSelect(a.row.id); }}
        />
      </div>
    </ErrorBoundary>
  );
};

YardsList.propTypes = {
  history: PropTypes.objectOf(PropTypes.any)
};
YardsList.defaultProps = {
  history: {}
};

const Yards = (props) => {
  const { match, history } = props;
  const [saving, setSaving] = useState(false);
  const setAllYards = useSetRecoilState(_AllYards);
  const [AllYards] = useRecoilState(_AllYards);

  const user = useContext(UserContext);
  const [record, setRecord] = useState({
    id: '',
    CreatedDate: '',
    CreatedBy: '',
    ModifiedBy: '',
    ModifiedDate: '',
    data: {
      Name: '',
      Status: 'Active',
      City: '',
      State: '',
    }
  });
  useEffect(() => {
    let mounted = true;
    firestore.collection('Yards').doc(match.params.id).onSnapshot((snap) => {
      if (snap.exists) {
        const d = snap.data();
        if (mounted)
          setRecord(d);
      }
      else if (mounted) {
        setRecord({ ...record, id: snap.id });
      }
    });
    return () => { mounted = false; };
  }, [match]);

  const handleSubmit = () => {
    setSaving(true);
    const colRef = firestore.collection('Yards');
    colRef.doc(record.id).set(record, { merge: true });
    setSaving(false);
    const allNewYards = JSON.parse(JSON.stringify(AllYards));
    allNewYards.push(record);
    setAllYards(allNewYards);
    toast.success('Yard Saved Successfully.', { autoClose: 5000 });
    history.push('/Yards');

  };
  const handleChange = (event, name, displayFromTypeahead) => {
    try {
      const id = name || event.target.name;
      const value = typeof name !== 'undefined' ? event : event.target.value;
      const changeRecord = { ...record };
      changeRecord.data[id] = value;
      if (typeof displayFromTypeahead !== 'undefined') {
        changeRecord.data[`${id}Display`] = displayFromTypeahead;
      }
      if (changeRecord.CreatedBy === '') {
        changeRecord.CreatedBy = user.name;
        changeRecord.CreatedDate = new Date().toJSON();
      }
      changeRecord.ModifiedBy = user.email;
      changeRecord.ModifiedDate = new Date().toJSON();
      setRecord(changeRecord);
    }
    catch (err) {
      toast.error(err.message);
    }
  };

  return (
    <ErrorBoundary componentName="Yard (Not the List view)">

      {
        saving ? (
          <div style={{
            display: 'block', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: '#efefef', opacity: 0.5, zIndex: 100
          }}
          />
        ) : <></>
      }
      <div style={{
        position: 'sticky', top: 0, left: 0, right: 0, width: '100%', backgroundColor: 'white', paddingTop: '10px', paddingBottom: '5px', paddingLeft: '0px', display: 'flex', justifyContent: 'flex-end', zIndex: 100
      }}
      >
        <Button variant="contained" color="primary" onClick={handleSubmit}>Save</Button>
        <Link style={{ textDecoration: 'none' }} to="/Yard"><Button variant="contained" style={{ marginLeft: '5px', color: "black" }}>Close</Button></Link>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <InputText name="Name" value={record.data.Name || ''} label="Name" onChange={handleChange} />
        </Grid>
        <Grid item xs={12}>
          <InputRadioGroup name="Status" value={record.data.Status || ''} label="Status" onChange={handleChange}>
            <InputRadio value="Active" label="Active" style={{ transform: 'scale(1.25)' }} />
            <InputRadio value="/Inactive" label="Inactive" style={{ transform: 'scale(1.25)' }} />
          </InputRadioGroup>
        </Grid>
        <Grid item xs={6}>

          <InputText name="City" value={record.data.City || ''} label="City" onChange={handleChange} />
        </Grid>
        <Grid item xs={6}>

          <InputText name="State" value={record.data.State || ''} label="State" onChange={handleChange} />
        </Grid>

      </Grid>
    </ErrorBoundary>
  );
};

Yards.propTypes = {
  history: PropTypes.objectOf(PropTypes.any),
  match: PropTypes.objectOf(PropTypes.any),
};
Yards.defaultProps = {
  history: {},
  match: {}
};
export {
  YardsList, Yards
};
