/* eslint-disable no-underscore-dangle */
import { atom } from 'recoil';

const SaveInitedOffline =
  () =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem('OfflineInited');
    // eslint-disable-next-line
    console.log(savedValue);
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue) => {
      if (typeof newValue === 'undefined') {
        localStorage.removeItem('OfflineInited');
      } else {
        localStorage.setItem('OfflineInited', JSON.stringify(newValue));
      }
    });
  };

export const _InitedOffline = atom({
  key: 'InitedOffline',
  default: { value: false, lastInited: new Date().toJSON() },
  effects_UNSTABLE: [SaveInitedOffline()],
});

export const _CurrentStatus = atom({
  key: 'CurrentStatus',
  default: '',
});

export const _CurrentJobNumberDisplay = atom({
  key: 'CurrentJobNumberDisplay',
  default: '',
});

export const _CurrentInspectionDateDate = atom({
  key: 'CurrentInspectionDateDate',
  default: '',
});

export const _CurrentInspector = atom({
  key: 'CurrentInspector',
  default: '',
});

export const _InspectionsFilterQuery = atom({
  key: 'InspectionsFilterQuery',
  default: {
    limit: 100,
    sort: null,
    direction: null,

    JobNumberDisplay: '',
    Inspector: '',
    InspectionDateDate: '',
    Status: '',
  },
});

export const _InitingOffline = atom({
  key: 'InitingOffline',
  default: false,
});

export const _AllCategories = atom({
  key: 'AllCategories',
  default: [],
  // effects_UNSTABLE: [localStorageEffect('CARE', 'Categories')],
});

export const _AllSubcategories = atom({
  key: 'AllSubcategories',
  default: [],
  // effects_UNSTABLE: [localStorageEffect('CARE', 'Subcategories')],
});

export const _AllInspectionTypes = atom({
  key: 'AllInspectionTypes',
  default: [],
  // effects_UNSTABLE: [localStorageEffect('CARE', 'InspectionTypes')],
});

export const _AllInspections = atom({
  key: 'AllInspections',
  default: [],
});

export const _AllYards = atom({
  key: 'AllYards',
  default: [],
  // effects_UNSTABLE: [localStorageEffect('CARE', 'Subcategories')],
});

export const _ShowChangelogModal = atom({
  key: 'ShowChangelogModal',
  default: false
});