/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { firestore } from '../firebase';

const Test = () => {
  const [objs, setObjs] = useState([]);

  useEffect(() => {
    firestore.collection('Inspections').get().then(snap => {
      const all = [];
      snap.forEach(doc => {
        const data = doc.data();
        if (typeof data.data.Location === 'undefined') {
          data.data.Location = 'Job';
          doc.ref.set(data, { merge: true });
        }
      });
    });
  }, []);

  return (
    <div>Test Page</div>
  );
};

export default Test;