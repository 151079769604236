/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Button,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { useRecoilState } from 'recoil';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { DataGrid } from '@material-ui/data-grid';
import { InputSelect } from '@aldridge/aldg-data-components';
import {
  InputText
} from '../_DataComponents/FormFields';
import { firestore } from '../firebase';
import '../_assets/dataGrid.css';
import { _AllInspectionTypes } from '../_Recoil/atoms';
import ErrorBoundary from '../_helpers/ErrorBoundary';

const UserAssignmentList = (props) => {
  const { history } = props;
  const [setups, setSetups] = useState([]);

  const _theme = useTheme();
  const matches = useMediaQuery(_theme.breakpoints.down('xs'));

  const useStyles = makeStyles(() => ({
    root: {
      '& .MuiDataGrid-footerContainer': {
        justifyContent: 'flex-start',
        marginLeft: '-12px'
      },
      '& .MuiDataGrid-row:nth-child(odd)': {
        backgroundColor: '#efefef'
      },
      '& .MuiGridPanel-root': {
        backgroundColor: 'purple'
      }
    },
  }));

  const customClasses = useStyles();

  const getFlex = (f) => (matches ? null : f);
  const getWidth = (w) => (matches ? w : null);

  const screenSizeWarning = () => <Alert severity="info">
    <AlertTitle>Attention</AlertTitle>
    Please use a tablet or computer to properly access this page!
  </Alert>;

  useEffect(() => {
    let mounted = true;
    firestore.collection('Employees').where('Status', '==', 'A').orderBy('LegalLastName').onSnapshot((snap) => {
      const allSetups = [];
      snap.forEach((doc) => {
        allSetups.push(doc.data());
      });
      if (mounted)
        setSetups(allSetups);
    });
    return () => { mounted = false; };
  }, []);
  const onSelect = (id) => {
    history.push(`/UserAssignment/${id}`);
  };
  const columns = [
    {
      field: 'Name',
      headerName: 'Name',
      flex: getFlex(15),
      width: getWidth(300),
      renderCell: (params) => <>{params.getValue(params.id, 'LegalLastName')}, {params.getValue(params.id, 'LegalFirstName')}</>,
      valueGetter: (params) => `${params.getValue(params.id, 'LegalLastName')}, ${params.getValue(params.id, 'LegalFirstName')}`
    },
    { field: 'Email', headerName: 'Email', flex: getFlex(20), width: getWidth(400) },
    { field: 'Title', headerName: 'Title', flex: getFlex(10), width: getWidth(150) },
    { field: 'DepartmentNumber', headerName: 'Department', flex: getFlex(7), width: getWidth(70) },
  ];
  return (
    <ErrorBoundary componentName="User Assingment List">
      {matches ? screenSizeWarning() : null}

      <div style={{
        width: '100%', display: 'flex', justifyContent: 'flex-end', marginBottom: '16px'
      }}
      />
      <div style={{ height: '100%', width: '100%' }}>
        <DataGrid
          className={customClasses.root}
          getRowId={(row) => row.prtmstid}
          rows={setups}
          columns={columns}
          // filterModel={{
          //   items: [
          //     { columnField: 'Name', operatorValue: 'contains', value: '' },
          //   ],
          // }}
          onRowClick={(a) => { onSelect(a.row.prtmstid); }}
        />
      </div>
    </ErrorBoundary>
  );
};

UserAssignmentList.propTypes = {
  history: PropTypes.objectOf(PropTypes.any)
};
UserAssignmentList.defaultProps = {
  history: {}
};

const UserAssignment = (props) => {
  const { match, history } = props;
  const [saving, setSaving] = useState(false);
  const [AllInspectionTypes] = useRecoilState(_AllInspectionTypes);
  const [record, setRecord] = useState({
    LegalFirstName: '',
    LegalLastName: '',
    Email: '',
    InspectionTypes: [],
  });
  useEffect(() => {
    let mounted = true;
    firestore.collection('Employees').doc(match.params.id).onSnapshot((snap) => {
      if (snap.exists) {
        const d = snap.data();
        if (mounted)
          setRecord(d);
      }
      else if (mounted) {
        setRecord({ ...record, id: snap.id });
      }
    });
    return () => { mounted = false; };
  }, [match]);

  const handleSubmit = () => {
    setSaving(true);
    const colRef = firestore.collection('Employees');
    colRef.doc(record.prtmstid).set(record, { merge: true });
    toast.success(`${record.PreferredFirstName} ${record.PreferredLastName}'s Inspection Types modified.`, { autoClose: 5000 });
    setSaving(false);
    history.push('/UserAssignment');
  };
  const addInspectionType = (e, v) => {
    const updatedRecord = { ...record };
    if (v === null || typeof v === 'undefined') return;
    updatedRecord.InspectionTypes = updatedRecord.InspectionTypes || [];

    // if (updatedRecord.InspectionTypess.filter((res) => res.id === v.value).length === 0) {
    //   const subRecord = InspectionTypess.filter((res) => res.id === v.value);
    //   if (subRecord.length > 0) {
    updatedRecord.InspectionTypes = e;
    //     console.log(updatedRecord)
    setRecord(updatedRecord);
    //   }
    // }
  };
  const removeFromList = (idx) => {
    const updatedRecord = { ...record };
    updatedRecord.InspectionTypes.splice(idx, 1);
    setRecord(updatedRecord);
  };
  const updateOrder = (reOrdered) => {
    const updatedRecord = { ...record };
    updatedRecord.InspectionTypes = reOrdered;
    setRecord(updatedRecord);
  };
  return (
    <ErrorBoundary componentName="User Assignment Individual User">
      {
        saving ? (
          <div style={{
            display: 'block', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: '#efefef', opacity: 0.5, zIndex: 100
          }}
          />
        ) : <></>
      }
      <div style={{
        position: 'sticky', top: 0, left: 0, right: 0, width: '100%', backgroundColor: 'white', paddingTop: '10px', paddingBottom: '5px', paddingLeft: '0px', display: 'flex', justifyContent: 'flex-end', zIndex: 100
      }}
      >
        <Button variant="contained" color="primary" onClick={handleSubmit}>Save</Button>
        <Link style={{ textDecoration: 'none' }} to="/UserAssignment"><Button variant="contained" style={{ marginLeft: '5px', color: 'black' }}>Close</Button></Link>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <InputText name="Name" value={`${record.LegalFirstName} ${record.LegalLastName}`} label="Name" disabled />
        </Grid>
        <Grid item xs={6}>
          <InputText name="EmployeeNumber" value={record.EmployeeNumber || ''} label="Employee Number" disabled />
        </Grid>
        <Grid item xs={4}>
          <InputText name="Email" value={record.Email || ''} label="Email" disabled />
        </Grid>
        <Grid item xs={4}>
          <InputText name="Title" value={record.Title || ''} label="Title" disabled />
        </Grid>
        <Grid item xs={4}>
          <InputText name="DepartmentNumber" value={record.DepartmentNumber || ''} label="Department Number" disabled />
        </Grid>
        <Grid item xs={12}>
          {/* {InspectionTypes.length === 0 ? <div> No options...</div> : <> */}
          <InputSelect
            multiple
            name="InspectionTypes"
            label="Add an Inspection Type"
            onChange={addInspectionType}
            options={AllInspectionTypes.map((res) => ({ label: res.data.InspectionType, value: res.id }))}
            value={record.InspectionTypes || []}
          />
          {/* </>} */}
        </Grid>
      </Grid>
    </ErrorBoundary>
  );
};

UserAssignment.propTypes = {
  history: PropTypes.objectOf(PropTypes.any),
  match: PropTypes.objectOf(PropTypes.any),
};
UserAssignment.defaultProps = {
  history: {},
  match: {}
};

export {
  UserAssignmentList, UserAssignment
};
