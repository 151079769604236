/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import {
  InputLabel,
  Select,
  ThemeProvider,
  FormControl,
  useTheme,
  Input,
  Chip,
  MenuItem
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { RedAsterisk } from './styles';

const InputSelect = (props) => {
  const {
    disabled,
    label,
    id,
    fullWidth,
    hidden,
    inputProps,
    invalid,
    onChange,
    readOnly,
    required,
    value,
    variant,
    name,
    options,
    multiple
  } = props;

  const useStyles = makeStyles((theme) => ({
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
  }));
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        // width: 250,
      },
    },
    variant: 'menu',
    getContentAnchorEl: null
  };
  const customClasses = useStyles();
  const theme = useTheme();

  function getStyles(o, oName, t) {
    return {
      fontWeight:
        oName.indexOf(o.value) === -1
          ? '400'
          : 'bold',
    };
  }

  const handleChange = (event) => {
    const propName = event.target.name;
    const propVal = event.target.value;
    onChange(propVal, propName);
  };
  return (
    <ThemeProvider theme={RedAsterisk}>
      {
        multiple
          ? (
            <FormControl fullWidth={fullWidth} hidden={hidden}>
              <InputLabel id="demo-mutiple-chip-label" shrink>{label}</InputLabel>
              <Select
                fullWidth
                labelId="demo-mutiple-chip-label"
                id="demo-mutiple-chip"
                multiple
                name={name}
                value={value}
                onChange={handleChange}
                input={<Input id="select-multiple-chip" />}
                MenuProps={{ style: { zIndex: 2300 } }}

                renderValue={(selected) => (
                  <div className={customClasses.chips}>
                    {selected.map((v) => (
                      <Chip
                        key={v}
                        label={options.filter((o) => o.value === v)[0]?.label}
                        className={customClasses.chip}
                      />
                    ))}
                  </div>

                )}
                // eslint-disable-next-line
                MenuProps={MenuProps}
              >
                {options.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    style={getStyles(option, value, theme)}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )
          : (
            <FormControl fullWidth={fullWidth} hidden={hidden}>
              <InputLabel htmlFor={name} required={required} shrink>{label}</InputLabel>
              <Select
                native
                value={value}
                onChange={onChange}
                name={name}
                disabled={disabled}
                readOnly={readOnly}
                error={invalid}
                variant={variant}
                id={id}
                inputProps={inputProps}
              >
                <option aria-label='None' value='' />
                {options.map(
                  (res) => (<option key={res.value} value={res.value}>{res.label}</option>)
                )}
              </Select>
            </FormControl>
          )
      }

    </ThemeProvider>
  );
};
InputSelect.propTypes = {
  /** Additional Properties to Pass to input eleement  */
  inputProps: PropTypes.objectOf(PropTypes.any),
  /** Array of options for the Select box */
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.objectOf(PropTypes.any), PropTypes.string, PropTypes.number])
  ).isRequired,
  /** Disables Form Field */
  disabled: PropTypes.bool,
  /** Form Field Label */
  label: PropTypes.string,
  /** Form Field HTML ID */
  id: PropTypes.string,
  /** Tells the Material UI Autocomplete Field to take up the full width of parent container */
  fullWidth: PropTypes.bool,
  /** Hides/Shows the Form Field */
  hidden: PropTypes.bool,
  /** Triggers a UI Invalid Response. Requires Application workflow for true invalid status */
  invalid: PropTypes.bool,
  /** Application OnChange Function that updates the transaction with the value */
  onChange: PropTypes.func,
  /** Forces the field to be read only */
  readOnly: PropTypes.bool,
  /** Triggers a UI Required Response. Requires Application workflow for true required status */
  required: PropTypes.bool,
  /** Material UI Variant property for Autocomplete Components */
  variant: PropTypes.string,
  /** Form Field name. Used for the Transaction key */
  name: PropTypes.string.isRequired,
  /** Transaction Value passed into Form Field */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.number)
  ]),
  multiple: PropTypes.bool
};
InputSelect.defaultProps = {
  disabled: false,
  label: 'No Label Provided',
  id: undefined,
  fullWidth: true,
  hidden: false,
  inputProps: undefined,
  invalid: false,
  onChange: () => { },
  readOnly: false,
  required: false,
  value: undefined,
  variant: undefined,
  multiple: false,
};
export default InputSelect;
