import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal, Button, Backdrop
} from '@material-ui/core';
import { basicStyles } from '../Pages/theme';

const Confirm = (props) => {
  const {
    proceedLabel,
    cancelLabel,
    title,
    modalBody,
    show,
    proceed,
    record
  } = props;
  const classes = basicStyles();
  return (
    <div className="static-modal">
      <Modal
        open={show}
        BackdropComponent={Backdrop}
        className={classes.modal}
      >
        <div className={classes.paper}>
          <div style={{ display: 'flex', justifyContent: 'space-between', padding: '1rem' }}>
            <h5 style={{ textDecoration: 'underline' }}>{title}</h5>
          </div>

          <div style={{ position: 'relative', flex: '1 1 auto', padding: '1rem' }}>{modalBody}</div>
          <div style={{
            display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'flex-end', padding: '0.75rem'
          }}
          >
            <Button
              color="default"
              variant="contained"
              onClick={() => proceed(false, record)}
              style={{ marginRight: '0.3rem' }}
            >{cancelLabel}
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => proceed(true, record)}
            >
              {proceedLabel}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

Confirm.propTypes = {
  proceedLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  title: PropTypes.string,
  modalBody: PropTypes.string,
  show: PropTypes.bool,
  proceed: PropTypes.func, // called when ok button is clicked.
  record: PropTypes.objectOf(PropTypes.any)
};
Confirm.defaultProps = {
  proceedLabel: '',
  cancelLabel: '',
  title: '',
  modalBody: '',
  show: false,
  proceed: () => { }, // called when ok button is clicked.
  record: {}
};

export default Confirm;
