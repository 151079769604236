/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable no-unused-vars */
import {
  Backdrop,
  IconButton, makeStyles, Modal, useTheme
} from '@material-ui/core';
import { CloudDone, CloudUpload } from "@material-ui/icons";
import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSignalAlt,
  faSignalAltSlash
  , faCheck, faCloud, faArrowUp
} from '@fortawesome/pro-solid-svg-icons';

import { _InitingOffline } from "../_Recoil/atoms";
import { basicStyles } from '../Pages/theme';
import ErrorBoundary from '../_helpers/ErrorBoundary';

const IndexedDbWrapper = ({ projectId, children }) => {
  const InitingOffline = useRecoilValue(_InitingOffline);
  const [pendingRecords, setPendingRecords] = useState([]);
  const [displayBoxOpen, setDisplayBoxOpen] = useState(false);
  const [IndexedDbExists, setIndexedDbExists] = useState(false);

  indexedDB.databases().then(r => {
    const exists = r.filter(d => d.name === `firestore/[DEFAULT]/${projectId}/main`);
    if (exists.length > 0) {
      setIndexedDbExists(true);
    }
  });

  let db;
  if (!InitingOffline && IndexedDbExists) {
    db = window.indexedDB.open(`firestore/[DEFAULT]/${projectId}/main`);
  }

  function arrayEquals(a, b) {
    return Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index]);
  }
  useEffect(() => {
    if (InitingOffline || !IndexedDbExists) {
      return null;
    }
    let transaction = null;
    db.onsuccess = () => {
      try {
        transaction = db.result.transaction(["mutations"], "readwrite").objectStore('mutations').getAll();
      } catch (err) {
        db.result.close();
        // eslint-disable-next-line
        console.log(err);
      }
    };
    const setI = setInterval(() => {
      try {
        transaction = db.result.transaction(["mutations"], "readwrite").objectStore('mutations').getAll();
        transaction.onsuccess = () => {
          try {
            const batchIds = transaction.result.map(r => r.batchId).sort();
            const currentBatchIds = pendingRecords.map(r => r.batchId).sort();
            if (!arrayEquals(batchIds, currentBatchIds)) {
              setPendingRecords(transaction.result);
            } else {
              setPendingRecords(pendingRecords);
            }
          } catch (err) {
            // eslint-disable-next-line
            console.log(err);
          }
        };
        transaction.onerror = (event) => {
          // eslint-disable-next-line
          console.log(event);
        };
      } catch (err) {
        db.result.close();
        // eslint-disable-next-line no-console
        console.log(err);
      }
    }, 5000);
    return () => { clearInterval(setI); };
  }, [InitingOffline, IndexedDbExists]);

  const displayChangeBox = (bool) => {
    setDisplayBoxOpen(bool || !displayBoxOpen);
  };
  const pulseBackground = makeStyles(() => ({
    animatedItem: {
      position: 'absolute',
      top: '-5px',
      left: '-5px',
      transform: 'translate(-5px, -5px)',
      borderRadius: '50%',
      width: '40px !important',
      height: '40px !important',
      border: '5px solid #e5e5e5',
      animation: '$pulse 1s ease-out infinite',
    },
    "@keyframes pulse": {
      "0%": {
        transform: 'scale(0)',
        opacity: '0.0'
      },
      '25%': {
        transform: 'scale(0.1)',
        opacity: '0.2'
      },
      '50%': {
        transform: 'scale(0.5)',
        opacity: '0.5'
      },
      '75%': {
        transform: 'scale(0.8)',
        opacity: '0.8'
      },
      '100%': {
        transform: 'scale(1)',
        opacity: '0.0'
      }
    }
  }))();

  const classes = basicStyles();

  function countDuplicates(records) {
    const counts = {};
    for (let i = 0; i < records.length; i++) {
      if (counts[records[i]]) {
        counts[records[i]] += 1;
      } else {
        counts[records[i]] = 1;
      }
    }
    return counts;
  }
  const displayPendingRecords = (r) => {
    const records = r.map(updates => updates?.mutations[0]?.update?.name?.replace(`projects/${projectId}/databases/(default)/documents/`, '').split('/')[0] || updates?.mutations[0]?.delete?.replace(`projects/${projectId}/databases/(default)/documents/`, '').split('/')[0]);
    return countDuplicates(records);
  };

  const countTest = displayPendingRecords(pendingRecords);
  return (
    <ErrorBoundary componentName="indexedDBWrapper">
      <Modal
        open={displayBoxOpen}
        onClose={() => displayChangeBox(false)}
        BackdropComponent={Backdrop}
        className={classes.modal}
      >
        <div className={classes.paper}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '1rem',
            }}
          >
            <h4 style={{ textDecoration: 'underline' }}>Pending Records</h4>
          </div>
          <div>
            <div style={{ position: 'relative', flex: '1 1 auto', padding: '1rem 0 0 0', display: 'flex', width: '100%', justifyContent: 'space-between' }}>
              <div style={{ padding: '0 5px', color: '#979797', fontWeight: 'bold' }}>Forms with Pending Records</div>
              <div style={{ padding: '0 5px', color: '#979797', fontWeight: 'bold' }}># of Pending Records</div>
            </div>
            {Object.keys(countTest).map(prop => (<div style={{ position: 'relative', flex: '1 1 auto', padding: '.75rem 0', display: 'flex', width: '100%', justifyContent: 'space-between' }} key={prop}>
              <div style={{ padding: '0 5px' }}>{prop}</div>
              <div style={{ padding: '0 5px' }}>{countTest[prop]}</div>
            </div>))}
          </div>
        </div>
      </Modal>
      <div style={{ position: 'relative', margin: '0 1px', display: 'flex', padding: '5px', backgroundColor: 'white', width: '30px', height: '30px', borderRadius: '50%', alignItems: 'center' }} title="Pending Records">
        <div className={pendingRecords.length > 0 ? pulseBackground.animatedItem : ''} />
        {
          pendingRecords.length === 0
            ? <span className="fa-layers fa-fw" style={{ cursor: 'pointer' }} onClick={displayChangeBox} role="button">
              <FontAwesomeIcon style={{ color: '#00ca00' }} icon={faCloud} />
              <FontAwesomeIcon style={{ color: 'white', fontSize: '.7rem', paddingTop: '2px' }} icon={faCheck} />
            </span>
            : <span className="fa-layers fa-fw" style={{ cursor: 'pointer' }} onClick={displayChangeBox} role="button">
              <FontAwesomeIcon style={{ color: '#ca0000' }} icon={faCloud} />
              <FontAwesomeIcon style={{ color: 'white', fontSize: '.7rem', paddingTop: '2px' }} icon={faArrowUp} />
            </span>
        }
      </div>
      {children.length > 0 ? <span onClick={displayChangeBox} style={{ cursor: 'pointer' }} role="button">{children}</span> : null}
    </ErrorBoundary>
  );
};

export default IndexedDbWrapper;

IndexedDbWrapper.propTypes = {
  projectId: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(PropTypes.any)
};
IndexedDbWrapper.defaultProps = {
  children: []
};