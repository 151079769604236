/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControlLabel, Checkbox, FormControl, FormLabel, FormGroup, ThemeProvider, withStyles
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { RedAsterisk } from '../../providers/MuiTheme';

const InvalidCheckbox = withStyles({
  root: {
    color: red[400],
    '&$checked': {
      color: red[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);
const InputCheckbox = (props) => {
  const {
    label, name, value, invalid, style, onChange, hidden, selectedValue, disabled
  } = props;

  const checkboxChange = (e) => {
    const vals = selectedValue.split(', ');
    const idx = vals.indexOf(e.target.value);
    if (idx > -1) vals.splice(idx, 1);
    else vals.push(e.target.value.trim());

    onChange(vals.filter((res) => res.trim() !== '').join(', '), name);
  };
  return (
    <FormControlLabel label={label} hidden={hidden} disabled={disabled} error={invalid} control={invalid ? <InvalidCheckbox value={value} style={style} checked={selectedValue.split(', ').indexOf(value) > -1} color="primary" onChange={checkboxChange} /> : <Checkbox value={value} style={style} checked={selectedValue.split(', ').indexOf(value) > -1} color="primary" onChange={checkboxChange} />} />
  );
};

InputCheckbox.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  invalid: PropTypes.bool,
  style: PropTypes.objectOf(PropTypes.any),
  onChange: PropTypes.func,
  hidden: PropTypes.bool,
  selectedValue: PropTypes.string,
  disabled: PropTypes.bool,
};
InputCheckbox.defaultProps = {
  label: '',
  name: '',
  value: '',
  invalid: false,
  style: {},
  hidden: false,
  onChange: () => {},
  selectedValue: '',
  disabled: false,
};

const InputCheckboxGroup = (props) => {
  const {
    name, onChange, children, invalid, selectedValue, disabled, label, fullWidth, hidden, required
  } = props;
  const checkboxes = Array.isArray(children) ? children : [children];
  return (
    <>
      <ThemeProvider theme={RedAsterisk}>
        <FormControl fullWidth={fullWidth} required={required}>
          <FormLabel>{label}</FormLabel>
          <FormGroup name={name} value={selectedValue} hidden={hidden}>
            {
              checkboxes.length > 0
                ? checkboxes.map((c) => React.cloneElement(c, {
                  onChange, name, invalid, key: `${name}_${c.props.value}`, selectedValue, disabled
                }))
                : <></>
            }
          </FormGroup>
        </FormControl>
      </ThemeProvider>
    </>
  );
};

InputCheckboxGroup.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  invalid: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  selectedValue: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  fullWidth: PropTypes.bool,
  hidden: PropTypes.bool,
  required: PropTypes.bool
};
InputCheckboxGroup.defaultProps = {
  invalid: false,
  disabled: false,
  label: '',
  fullWidth: true,
  hidden: false,
  required: false
};

export {
  InputCheckbox, InputCheckboxGroup
};
