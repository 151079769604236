import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import ChangelogList from './components/changelog/ChangelogList';
import Changelog from './components/changelog/Changelog';

const ChangelogPage = (props) => {
  document.title = 'Changelog';
  const { match, history } = props;
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          {typeof match.params.id === 'undefined' ? (
            <>
              <ChangelogList history={history} match={match} />
            </>
          ) : (
            <Changelog history={history} match={match} />
          )}
        </Grid>
      </Grid>
    </>
  );
};

ChangelogPage.propTypes = {
  match: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any),
};
ChangelogPage.defaultProps = {
  match: {
    params: {}
  },
  history: {
    push: () => { }
  }
};
export default ChangelogPage;
