import { createTheme, makeStyles } from '@material-ui/core/styles';

const RedAsterisk = createTheme({
  overrides: {
    MuiFormLabel: {
      asterisk: {
        visibility: 'hidden',
        color: 'white',
      },
      root: {
        "&$required": {
          "fontWeight": "bold",
          "color": '#3f51b5'
        }
      }
    }
  }
});
const RedButton = makeStyles({
  root: {
    color: 'white',
    backgroundColor: '#d92550',
    border: '1px solid #d92550',
    '&:hover': {
      backgroundColor: '#b81f44',
      borderColor: '#ad1e40'
    }
  }
});

const RedLink = makeStyles({
  root: {
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#d92550',
    '&:hover': {
      color: '#b81f44',
    }
  }
});

const GreenButton = makeStyles({
  root: {
    color: 'white',
    backgroundColor: '#3ac47d',
    border: '1px solid #3ac47d',
    '&:hover': {
      backgroundColor: '#31a66a',
      borderColor: '#2e9d64'
    }
  }
});

const GreenLink = makeStyles({
  root: {
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#3ac47d',
    '&:hover': {
      color: '#31a66a',
    }
  }
});

const AccordionClass = makeStyles({
  root: {
    border: '1px solid #ccc',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&$expanded': {
      margin: '8px 0',
    },
  },
  expanded: {},
});
const AccordionSummaryClass = makeStyles({
  root: {
    backgroundColor: '#eee',
    boxShadow: 'inset 0px -19px 4px -19px #aaa',
    '&$expanded': {
      // maxHeight: '48px',
      minHeight: '48px',
      color: 'white',
      // border: '1px solid #ccc',
    },
  },
  expanded: {},
});
const AccordionDetailsClass = makeStyles({
  root: {
    flexDirection: 'column',
  },
});


const AccordionBlueClass = makeStyles({
  root: {
    border: '1px solid #ccc',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&$expanded': {
      border: '1px solid #2a56a7',
      margin: '8px 0',
    },
  },
  expanded: {},
});
const AccordionBlueSummaryClass = makeStyles({
  root: {
    backgroundColor: '#eee',
    boxShadow: 'inset 0px -19px 4px -19px #aaa',
    '&$expanded': {
      maxHeight: '48px',
      minHeight: '48px',
      backgroundColor: 'rgb(59, 120, 231)',
      color: 'white',
      // border: '1px solid #ccc',
    },
  },
  expanded: {},
});
const AccordionBlueDetailsClass = makeStyles({
  root: {
    flexDirection: 'column',
  },
});

export {
  RedAsterisk,
  RedButton,
  RedLink,
  GreenButton,
  GreenLink,
  AccordionClass,
  AccordionSummaryClass,
  AccordionDetailsClass,
  AccordionBlueClass,
  AccordionBlueSummaryClass,
  AccordionBlueDetailsClass,
};
