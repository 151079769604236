/* eslint-disable import/no-extraneous-dependencies */
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import { toast } from 'react-toastify';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
};

firebase.initializeApp(firebaseConfig);

const GoogleProvider = new firebase.auth.GoogleAuthProvider();
GoogleProvider.setCustomParameters({
  prompt: 'select_account',
});
let confirmCode = null;
let codeSent = null;

const recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
  'recaptcha-container',
  {
    size: 'invisible',
    callback: (response) => {
      // reCAPTCHA solved, allow signInWithPhoneNumber.
      codeSent = true;
      window.confirmationResult = response;
      // onSignInSubmit();
    },
  }
);

if (window.location.hostname === 'localhost') {
  firebase.functions().useEmulator('localhost', 5001);
}

export const auth = firebase.auth();
export const fb = firebase;
export const firestore = firebase.firestore();
window.MYFIRESTORE = firestore;

firestore.enablePersistence({ synchronizeTabs: true }).catch((err) => {
  toast.error(err.message);
});

if (
  localStorage.getItem('offlineMode') !== null &&
  localStorage.getItem('offlineMode').toString() === 'true'
) {
  firestore.disableNetwork();
} else {
  firestore.enableNetwork();
}

auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL).catch((err) => {
  toast.error(err.message);
});

export const functions = firebase.functions();
export const storage = firebase.storage();
export const config = firebaseConfig;

export const CONFIRM_CODE = confirmCode;
export const CODE_SENT = codeSent;
export const signInWithGoogle = () => {
  if ('standalone' in window.navigator) {
    auth
      .getRedirectResult()
      .then((result) => {
        if (!result.user) {
          auth.signInWithRedirect(GoogleProvider);
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
        toast.error(error.message);
      });
  } else {
    auth.signInWithPopup(GoogleProvider).catch((err) => {
      // eslint-disable-next-line no-console
      console.log(err);
      toast.error(`${err.message}`);
    });
  }
};
export const signInWithPhone = (callback) => {
  const phoneNumber = document.getElementById('userPhoneNumberLogIn').value;
  const PHONENUMBER = phoneNumber.length === 10 ? `+1${phoneNumber}` : null;
  if (PHONENUMBER === null) {
    throw new Error('Phone Number needs to be 10 characters long.');
  }
  auth.signInWithPhoneNumber(PHONENUMBER, recaptchaVerifier).then((res) => {
    callback(res);
    confirmCode = res;
  });
};
export const verifyCode = (callback) => {
  const code = document.getElementById('userVerificationCode').value;
  confirmCode.confirm(code).then((res) => {
    callback(res);
  });
};
