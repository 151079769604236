import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TextField, ThemeProvider } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { RedAsterisk } from '../../providers/MuiTheme';
import { firestore } from '../../firebase';
import uuid from '../../_helpers/uuid';

const InputAutocomplete = (props) => {
  const {
    disableClearable,
    disabled,
    label,
    id,
    fullWidth,
    hidden,
    invalid,
    onChange,
    readOnly,
    required,
    value,
    variant,
    multiple,
    name,
    valueKey,
    optionDisplay,
    collection,
    where,
    orderBy,
    filterDataset,
    requirement,
    enterprise,
  } = props;

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  // const fs = enterprise ? entFirestore : firestore;
  // console.log(fs);
  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) { setLoading(true); }
    if (typeof requirement === 'undefined') {
      let colRef = firestore.collection(collection);
      if (typeof where !== 'undefined') {
        where.forEach((w) => {
          colRef = colRef.where(w[0], w[1], w[2]);
        });
      }
      if (typeof orderBy !== 'undefined') {
        colRef = colRef.orderBy(orderBy);
      }
      colRef.onSnapshot((results) => {
        let dataOptions = [];
        results.forEach((doc) => {
          const record = doc.data();
          dataOptions.push(record);
        });
        if (typeof filterDataset !== 'undefined') {
          dataOptions = filterDataset(dataOptions);
        }
        if (isSubscribed) {
          setLoading(false);
          setData(dataOptions);
        }
      }, (err) => {
        // eslint-disable-next-line
        console.log(err);
      });
    }
    return () => (isSubscribed = false);
  }, []);

  useEffect(() => {
    let isSubscribed = true;
    if (typeof requirement !== 'undefined' && requirement !== '') {
      if (isSubscribed) { setLoading(true); }
      let colRef = firestore.collection(collection);
      if (typeof where !== 'undefined') {
        where.forEach((w) => {
          colRef = colRef.where(w[0], w[1], w[2]);
        });
      }
      if (typeof orderBy !== 'undefined') {
        colRef = colRef.orderBy(orderBy);
      }
      colRef.onSnapshot((results) => {
        let dataOptions = [];
        results.forEach((doc) => {
          const record = doc.data();
          dataOptions.push(record);
        });
        if (typeof filterDataset !== 'undefined') {
          dataOptions = filterDataset(dataOptions);
        }
        if (isSubscribed) {
          setLoading(false);
          setData(dataOptions);
        }
      })
        .catch((err) => {
          // eslint-disable-next-line
          console.log(err);
        });
    }
    return () => (isSubscribed = false);
  }, [requirement]);

  useEffect(() => {
    let isSubscribed = true;
    if (typeof value !== 'undefined') {
      const current = [];
      for (let i = 0; i < value.length; i++) {
        const rec = data.filter((dataObj) => dataObj[valueKey] === value[i]);
        if (rec.length > 0) current.push(rec[0]);
      }
      if (isSubscribed) setSelected(current);
    }
    return () => (isSubscribed = false);
  }, [data, value]);

  if (loading && !hidden) return <div>Loading Enterprise Data...</div>;

  const displayString = (options, optiondisplay) => {
    const displayStr = [];
    optiondisplay.forEach((option) => {
      const displaycheck = option.indexOf('option_');
      if (displaycheck === -1) displayStr.push(option);
      else {
        const val = (() => {
          const tempO = enterprise ? options : options.data;
          const o = tempO || {};
          return o[option.split('option_')[1]] || '';
        })();
        displayStr.push(val);
      }
    });
    if (Object.keys(options).length === 0) return '';
    return displayStr.filter((res) => res !== '').join('');
  };
  const createLabelKeys = (option) => displayString(option, optionDisplay);

  const handleChange = (e, v) => {
    const arrVal = ((val) => {
      if (typeof v === 'undefined' || v === null) return [];
      return Array.isArray(val) ? val : [val];
    })(v);
    if (arrVal.length > 1) {
      const display = arrVal.map((res) => createLabelKeys(res)).join(', ');
      onChange(arrVal.map((res) => res[valueKey]), name, display);
    }
    else if (arrVal.length === 1) {
      const display = createLabelKeys(arrVal[0]);
      onChange([arrVal[0][valueKey]], name, display);
    }
    else {
      onChange([], name);
    }
    setSelected(arrVal);
  };

  // console.log(selected);
  const selectedVal = selected.length > 0 ? selected[0] : {};
  const isMultiSelect = multiple ? selected : selectedVal;
  return (
    <ThemeProvider theme={RedAsterisk}>
      <Autocomplete
        id={id || uuid()}
        freeSolo
        getOptionLabel={createLabelKeys}
        options={data}
        name={name}
        fullWidth={fullWidth}
        renderInput={(params) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <TextField {...params} label={`${label}`} fullWidth={fullWidth} variant={variant} required={required} error={invalid} />
        )}
        onChange={handleChange}
        multiple={multiple}
        disabled={disabled}
        value={isMultiSelect}
        hidden={hidden}
        readOnly={readOnly}
        disableClearable={disableClearable}
      />

    </ThemeProvider>
  );
};
InputAutocomplete.propTypes = {
  disableClearable: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  selectLabel: PropTypes.string,
  id: PropTypes.string,
  fullWidth: PropTypes.bool,
  hidden: PropTypes.bool,
  inputProps: PropTypes.objectOf(PropTypes.any),
  invalid: PropTypes.bool,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  variant: PropTypes.string,
  name: PropTypes.string.isRequired,
  multiple: PropTypes.bool,

  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.number)
  ]),
  valueKey: PropTypes.string,
  optionDisplay: PropTypes.arrayOf(PropTypes.string),
  collection: PropTypes.string.isRequired,
  where: PropTypes.arrayOf(PropTypes.any),
  orderBy: PropTypes.string,
  filterDataset: PropTypes.func,
  disableToken: PropTypes.bool,
  renderToken: PropTypes.func,
  requirement: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  enterprise: PropTypes.bool
};
InputAutocomplete.defaultProps = {
  disableClearable: false,
  disabled: false,
  label: 'No Label Provided',
  selectLabel: 'Select a Record...',
  id: undefined,
  fullWidth: true,
  hidden: false,
  inputProps: {},
  invalid: false,
  onChange: () => { },
  readOnly: false,
  required: false,
  variant: undefined,
  multiple: false,

  value: undefined,
  valueKey: '',
  optionDisplay: [],
  where: undefined,
  orderBy: undefined,
  filterDataset: undefined,
  disableToken: false,
  renderToken: undefined,
  requirement: undefined,
  enterprise: true
};
export default InputAutocomplete;
