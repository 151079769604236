import { createTheme, makeStyles } from '@material-ui/core/styles';

const RedAsterisk = createTheme({
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: '#db3131',
        '&$error': {
          color: '#db3131'
        }
      }
    },
    MuiPopover: {
      root: {
        zIndex: '2500 !important'
      }
    }
  }
});


const BolderLabel = makeStyles({
  root: {
    fontWeight: 600
  }
});

export { RedAsterisk, BolderLabel };
