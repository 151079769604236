/* eslint-disable no-unused-vars */
/* eslint-disable prefer-const */
/* eslint-disable camelcase */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import firebase from 'firebase';
import { useRecoilValue } from 'recoil';
import { toast } from 'react-toastify';
import { ExpandMore } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { makeStyles } from '@material-ui/core/styles';
import {
  InputSelect, InputTextarea, InputDate,
} from '@aldridge/aldg-data-components';
import Files from '../_DataComponents/Files';
import { firestore, config } from '../firebase';
import { UserContext } from '../providers/UserProvider';
import Subcategory from './Subcategory';
import { GreenButton, AccordionClass, AccordionSummaryClass, AccordionDetailsClass } from '../providers/MuiTheme';
import { useFirebaseMutation } from '../_helpers/fetch';
import { _AllCategories, _AllSubcategories } from '../_Recoil/atoms';
import ErrorBoundary from '../_helpers/ErrorBoundary';

const ObservationsListUnMemo = (props) => {
  const {
    Inspection, InspectionSubmit, Category, Observations, SafeObservations
  } = props;
  const AllCategories = useRecoilValue(_AllCategories);
  const AllSubcategories = useRecoilValue(_AllSubcategories);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [TotalObservations, setTotalObservations] = useState([]);
  const TotalSafe = TotalObservations.filter((res) => res.data.ObservationType === 'Safe').length;
  const TotalAtRisk = TotalObservations.filter((res) => res.data.ObservationType === 'At-Risk').length;
  const hasOpenAtRisk = TotalObservations.filter((res) => res.data.ObservationType === 'At-Risk' && res.data.Status === 'Open').length;

  useEffect(() => {
    let mounted = true;
    const cat = AllCategories.filter((c) => c.id === Category.id);
    setCategories(cat);
    const subcats = [];
    if (cat.length > 0) {
      cat[0].data.Subcategories.forEach((c) => {
        const sub = AllSubcategories.filter((s) => s.id === c.id);
        if (sub.length > 0 && sub[0].data.Status === 'Active')
          subcats.push(sub[0]);
      });
      if (mounted)
        setSubcategories(subcats);
    }
    const currentObservations = Observations.filter(res => res.data.CategoryID === Category.id);
    if (mounted)
      setTotalObservations(currentObservations);
    return () => { mounted = false; };
  }, [AllCategories, AllSubcategories, Observations]);

  const useStyles = makeStyles({
    // style rule
    atRisk: {
      color: '#d92550',
      padding: '5px'
    },
    safe: {
      color: '#3ac47d',
      padding: '5px'
    },
  });
  const customClasses = useStyles();
  const accordionClass = AccordionClass();
  const accordionSummaryClass = AccordionSummaryClass();
  const accordionDetailsClass = AccordionDetailsClass();

  const title = (() => {
    if (categories.length > 0) {

      const t = categories[0].data.CategoryDisplay;
      return (categories[0].data.SafetyAbsolute === 'Yes') ? `****${t}****` : t;
    }
    return '';
  })();
  const propKey = (() => {
    if (categories.length > 0) {

      const t = categories[0].data.CategoryDisplay;
      return t.replace(/[^a-zA-Z0-9]/gi, '');
    }
    return '';
  })();

  const openAccordion = () => {
    window.currentCategory = window.currentCategory === propKey ? '' : propKey;
  };
  return (
    <ErrorBoundary componentName="Observations List">
      {categories.length > 0
        ? (
          <Grid item xs={12}>
            <Accordion classes={accordionClass} defaultExpanded={typeof window.currentCategory !== 'undefined' && window.currentCategory !== '' && window.currentCategory === propKey || false} id={propKey}>
              <AccordionSummary
                classes={accordionSummaryClass}
                onClick={openAccordion}
                expandIcon={<ExpandMore />}
                id={`${categories[0].id}-content`}
                style={{ textTransform: 'uppercase', fontWeight: '600', color: hasOpenAtRisk > 0 ? '#d92550' : 'black' }}
              >
                <div style={{
                  display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center'
                }}
                >
                  <Grid container>
                    {title}
                  </Grid>
                  <Grid container justifyContent='flex-end'>
                    <span className={customClasses.safe}>Safe: {TotalSafe}</span>
                    <span className={customClasses.atRisk}>At Risk: {TotalAtRisk}</span>
                  </Grid>
                </div>
              </AccordionSummary>
              <AccordionDetails classes={accordionDetailsClass}>
                {
                  subcategories?.length > 0 ?
                    subcategories.map((subs) => {
                      const obv = Observations.filter((res) => res.data.SubcategoryID === subs.id);
                      return (
                        <Subcategory
                          Inspection={Inspection}
                          subcategory={subs}
                          categoryKey={categories[0].id}
                          key={subs.id}
                          InspectionSubmit={InspectionSubmit}
                          Observations={obv}
                          SafeObservations={SafeObservations}
                        />);
                    }) : <> </>
                }
              </AccordionDetails>
            </Accordion>
          </Grid>
        )
        : <></>}
    </ErrorBoundary>
  );
};

ObservationsListUnMemo.propTypes = {
  Inspection: PropTypes.objectOf(PropTypes.any).isRequired,
  Category: PropTypes.objectOf(PropTypes.any).isRequired,
  InspectionSubmit: PropTypes.func.isRequired,
  Observations: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  SafeObservations: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
};
ObservationsListUnMemo.defaultProps = {
};

const ObservationsUnMemo = (props) => {
  const { match, history } = props;
  const [saving, setSaving] = useState(false);
  const user = useContext(UserContext);
  const [record, setRecord] = useState({
    id: '',
    CreatedDate: '',
    CreatedBy: '',
    ModifiedBy: '',
    ModifiedDate: '',
    data: {
      InspectionID: '',
      CategoryID: '',
      SubcategoryID: '',
      Severity: '',
      RootCause: '',
      ActionTaken: '',
      CorrectedBy: '',
      CompletedDate: '',
      Comments: '',
      ObservationType: ''
    }
  });
  const [Inspection, setInspection] = useState({ data: {} });
  const [Category] = useState({});
  const [SubcategoryR] = useState({});
  const { firebaseCall: sendEmail } = useFirebaseMutation('SendEmail', {});

  const InspectionKey = `${Inspection.data.JobNumberDisplay}/${Inspection.data.Inspector}/${dayjs(Inspection.CreatedDate).format('YYYY-MM-DDThh.mm.ss')}`;
  useEffect(() => {
    let unmounted = false;
    if (typeof match.params.id === 'undefined' || match.params.id === 'undefined') {
      toast.error(`This Observation Record doesn't exist.`);
      history.push(`/Inspections/${match.params.InspectionID}`);
    }
    if (!unmounted) {
      firestore.collection('Inspections').doc(match.params.InspectionID)
        .onSnapshot((snap) => {
          if (snap.exists) {
            const i = snap.data();
            if (!unmounted)
              setInspection(i);
          }
        });
    }
    return () => { unmounted = true; };
  }, [match]);
  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      if (typeof Inspection.id !== 'undefined' && Inspection.id !== '') {
        firestore.collection('Inspections')
          .doc(Inspection.id)
          .collection('Observations')
          .doc(match.params.id)
          .onSnapshot((obSnap) => {
            if (obSnap.exists) {
              const d = obSnap.data();
              if (!unmounted)
                setRecord(d);
            }
          });
      }
    }
    return () => { unmounted = true; };
  }, [Inspection]);

  const handleSubmit = () => {
    setSaving(true);
    const colRef = firestore.collection('Inspections').doc(Inspection.id).collection('Observations');
    colRef.doc(record.id).set(record, { merge: true });
    setSaving(false);
    toast.success('Observation Saved Successfully.', { autoClose: 5000, toastId: "ObservationSavedSuccessfully" });
    // history.push(`/Inspections/${record.data.InspectionID}`);
  };

  const ObservationEmail = (r, to, subject) => {
    const html = `
        <table style="border-spacing:0;border-collapse:collapse;padding:0;vertical-align:top;text-align:left;border:1px solid #ddd;width:100%">
          <tr>
            <td colspan="2" valign="top" style="font-size:16pt;font-weight:bold;text-align:left;color:#ffffff;background-color:#2c3f50;padding:0.5rem">
              <div style="margin-left:0.000px">Observation Information</div>
            </td>
            <td style="background-color:#2c3f50;"></td>
          </tr>
          <tr>
            <td colspan="2" valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Category</td>
            <td>${Category.data.Category}</td>
          </tr>
          <tr>
            <td colspan="2" valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Subcategory</td>
            <td>${SubcategoryR.data.Subcategory}</td>
          </tr>
          <tr>
            <td colspan="2" valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Severity</td>
            <td>${r.data.Severity}</td>
          </tr>
          <tr>
            <td colspan="2" valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Root Cause</td>
            <td>${r.data.RootCause}</td>
          </tr>
          <tr>
            <td colspan="2" valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Comments</td>
            <td>${r.data.Comments}</td>
          </tr>
        </table>
        <a href='${window.location.href}'>
          <div style='font-size:12pt;font-weight:bold;color:#fff;background-color:#4285f4;text-align:center;border-radius: 0.15rem;width: fit-content;margin: 0.5rem 0;padding: 0.5rem 0.75rem;'>View in App</div>
        </a>
        `;
    // sendEmail({ to, subject, html });
  };

  const disableForUsers = (record.data.Status === 'Complete' && !user.admin);

  const handleComplete = () => {
    setSaving(true);
    const errs = [];
    if (record.data.Severity === '') errs.push('Severity Not Completed');
    if (record.data.RootCause === '') errs.push('Root Cause Not Completed');
    if (record.data.ActionTaken === '') errs.push('Action Taken Not Completed');
    if (record.data.CorrectedBy === '') errs.push('Corrected By Not Completed');
    if (record.data.CompletedDate === '') errs.push('Completed Date Not Completed');
    if (errs.length > 0) {
      toast.error(`Not all fields have been filled out: ${errs.join(', ')}.`, { toastId: 'handleCompleteMissingFields' });
      record.data.Status = 'Open';
      const colRef = firestore.collection('Inspections').doc(Inspection.id).collection('Observations');
      colRef.doc(record.id).set(record, { merge: true });
      setSaving(false);
      return;
    }
    record.data.Status = 'Complete';
    const colRef = firestore.collection('Inspections').doc(Inspection.id).collection('Observations');
    colRef.doc(record.id).set(record, { merge: true });
    setSaving(false);
    if (record.data.Severity === 'Life Threat') {
      // console.log('EMAIL OB');
      ObservationEmail(record, ['tgibbs@aldridgegroup.com'], 'Life Threat');
      // ObservationEmail(record, ['tgibbs@aldridgegroup.com', 'elucas@aldridgegroup.com', 'ifrishman@aldridgegroup.com'], 'Life Threat');
    }
    if (typeof Category.data !== 'undefined' && Category.data.SafetyAbsolute === 'Yes') {
      // console.log('EMAIL SARAH');
      ObservationEmail(record, ['tgibbs@aldridgegroup.com'], 'Safety Absolute');
      // ObservationEmail(record, ['tgibbs@aldridgegroup.com', 'elucas@aldridgegroup.com', 'ifrishman@aldridgegroup.com'], 'Safety Absolute');
    }
    toast.success('Observation Completed Successfully.', { autoClose: 5000, toastId: 'ObservationCompeleted' });
    firebase.analytics().logEvent('at_risk_observation_completed', { observation_id: record.id, inspection_id: Inspection.id, category_id: record.data.CategoryID, subcategory_id: record.data.SubcategoryID, user: user.name });
    history.push(`/Inspections/${record.data.InspectionID}`);
  };

  const handleChange = (event, name, displayFromTypeahead) => {
    try {
      const id = name || event.target.name;
      const value = typeof name !== 'undefined' ? event : event.target.value;
      const changeRecord = { ...record };
      changeRecord.data[id] = value;
      if (typeof displayFromTypeahead !== 'undefined') {
        changeRecord.data[`${id}Display`] = displayFromTypeahead;
      }
      if (changeRecord.CreatedBy === '') {
        changeRecord.CreatedBy = user.name;
        changeRecord.CreatedDate = new Date().toJSON();
      }
      changeRecord.ModifiedBy = user.email;
      changeRecord.ModifiedDate = new Date().toJSON();
      setRecord(changeRecord);
    }
    catch (err) {
      toast.error(err.message);
    }
  };
  const SeverityOptions = [
    { label: 'Low', value: 'Low' },
    { label: 'Medium', value: 'Medium' },
    { label: 'High', value: 'High' },
    { label: 'Life Threat', value: 'Life Threat' },
  ];
  const RootCauseOptions = [
    { label: 'Communication', value: 'Communication' },
    { label: 'Planning', value: 'Planning' },
    { label: 'Training', value: 'Training' },
    { label: 'Disagreement on Safe Work Practices', value: 'Disagreement on Safe Work Practices' },
    { label: 'Personal Choice', value: 'Personal Choice' },
  ];
  const ActionTakenOptions = [
    { label: 'Fixed', value: 'Fixed' },
    { label: 'Covered', value: 'Covered' },
    { label: 'Cleaned', value: 'Cleaned' },
    { label: 'Repaired', value: 'Repaired' },
    { label: 'Replaced', value: 'Replaced' },
    { label: 'Trained Employee', value: 'Trained Employee' },
    { label: 'Installed', value: 'Installed' },
    { label: 'Removed', value: 'Removed' },
    { label: 'PPE Provided', value: 'PPE Provided' },
    { label: 'Secured', value: 'Secured' },
    { label: 'Protected', value: 'Protected' },
  ];
  const CorrectedByOptions = [
    { label: 'Crew Leader/GF/Superintendent', value: 'Crew Leader/GF/Superintendent' },
    { label: 'Employee', value: 'Employee' },
    { label: 'Owner', value: 'Owner' },
    { label: 'Subcontractor', value: 'Subcontractor' },
    { label: 'General Contractor', value: 'General Contractor' },
  ];

  const classes = GreenButton();
  return (
    <ErrorBoundary componentName="Observations">
      {
        saving ? (
          <div style={{
            display: 'block', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: '#efefef', opacity: 0.5, zIndex: 100
          }}
          />
        ) : <></>
      }
      <div style={{
        position: 'sticky', top: 0, left: 0, right: 0, width: '100%', backgroundColor: 'white', paddingTop: '10px', paddingBottom: '5px', paddingLeft: '0px', display: 'flex', justifyContent: 'flex-end', zIndex: 100
      }}
      >
        <Button variant="contained" color="primary" onClick={handleSubmit}>Save</Button>
        {record.data.ObservationType === 'At-Risk' ? <Button variant="contained" className={classes.root} style={{ marginLeft: '5px' }} onClick={handleComplete}>Complete</Button> : <></>}
        <Link style={{ textDecoration: 'none' }} to={`/Inspections/${match.params.InspectionID}`}><Button variant="contained" style={{ marginLeft: '5px', color: "black" }}>Close</Button></Link>
      </div>
      <Grid container spacing={1}>
        {
          record.data.ObservationType === 'At-Risk'
            ? (
              <>
                <Grid item md={6} xs={12}>
                  <InputSelect name="Severity" value={record.data.Severity || 'None Selected'} label="Severity" onChange={handleChange} options={SeverityOptions} disabled={disableForUsers} />
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputSelect name="RootCause" value={record.data.RootCause || ''} label="Root Cause" onChange={handleChange} options={RootCauseOptions} disabled={disableForUsers} />
                </Grid>

                <Grid item md={6} xs={12}>
                  <InputSelect name="ActionTaken" value={record.data.ActionTaken || ''} label="Action Taken" onChange={handleChange} options={ActionTakenOptions} disabled={disableForUsers} />
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputSelect name="CorrectedBy" value={record.data.CorrectedBy || ''} label="Corrected By" onChange={handleChange} options={CorrectedByOptions} disabled={disableForUsers} />
                </Grid>

                <Grid item md={6} xs={12}>
                  <InputDate type="date" name="CompletedDate" value={record.data.CompletedDate || ''} label="Completed Date" onChange={handleChange} disabled={disableForUsers} />
                </Grid>
                <Grid item md={6} xs={6} />
              </>
            ) : <></>
        }
        <Grid item md={6} xs={12}>
          <Files label="Observed Photos" name="ObservedPhotos" multiple folder={`${InspectionKey}/Observation/${record.CreatedBy}/${record.CreatedDate.replace(/\//g, '-')}/Observed`} bucket={config.storageBucket} disabled={record.data.InspectionStatus === "Complete"} />
        </Grid>
        <Grid item md={6} xs={12}>
          <Files label="Corrected Photos" name="CorrectedPhotos" multiple folder={`${InspectionKey}/Observation/${record.CreatedBy}/${record.CreatedDate.replace(/\//g, '-')}/Corrected`} bucket={config.storageBucket} disabled={record.data.InspectionStatus === "Complete"} />
        </Grid>
        <Grid item xs={12}>
          <InputTextarea name="Comments" value={record.data.Comments || ''} label="Comments" onChange={handleChange} disabled={record.data.InspectionStatus === "Complete"} />
        </Grid>
      </Grid>
    </ErrorBoundary>
  );
};

ObservationsUnMemo.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};
ObservationsUnMemo.defaultProps = {
};

const ObservationsList = React.memo(ObservationsListUnMemo);
const Observations = React.memo(ObservationsUnMemo);

export { ObservationsList, Observations };
