import { colors, makeStyles } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import shadows from './shadows';
import typography from './typography';

const basicStyles = makeStyles((theme) => ({
  catRoot: {
    '& .MuiDataGrid-footerContainer': {
      justifyContent: 'flex-start',
      paddingLeft: '0px',
      '& .MuiTablePagination-actions': {
        marginLeft: '0px',
      },
    },
    '& .MuiDataGrid-row:nth-child(odd)': {
      backgroundColor: '#efefef',
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '2100 !important',
  },
  icon: {
    fontSize: '2rem',
    transform: 'scale(calc(5/6))',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  row1: {
    '&:nth-child(odd)': {
      backgroundColor: '#efefef',
    },
    '&:nth-child(even)': {
      backgroundColor: '#fff',
    },
  },
}));

const theme = createTheme({
  palette: {
    background: {
      default: '#3b78e7',

      paper: colors.common.white,
    },
    primary: {
      contrastText: '#ffffff',
      main: '#3b78e7',
    },
    text: {
      primary: '#172b4d',
      secondary: '#6b778c',
    },
  },
  shadows,
  typography,
});

export { theme, basicStyles };
