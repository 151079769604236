import { RecoilRoot } from 'recoil';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import Loader from 'react-loaders';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { OfflineProvider } from '@aldridge/aldg-helpers';
import './_assets/base.scss';
import 'react-toastify/dist/ReactToastify.css';
import Header from './_GlobalComponents/Header';
import Footer from './_GlobalComponents/Footer';
import Application from './Pages/Application';
import UserProvider from './providers/UserProvider';
import OfflineDataProvider from './providers/OfflineDataProvider';
import ServiceWorkerWrapper from './ServiceWorkerWrapper';
import { functions } from './firebase';
import ChangelogModal from './ChangelogModal';

const rootElement = document.getElementById('root');
require('dotenv').config();

const renderApp = () => {
  ReactDOM.render(
    <RecoilRoot>
      <OfflineProvider functions={functions} funcName="pingLatency" url="https://us-central1-aldg-inspections.cloudfunctions.net/pingLatency">
        <UserProvider>
          <OfflineDataProvider>
            <BrowserRouter>
              <ToastContainer
                position="bottom-left"
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
              <Suspense fallback={
                (
                  <div className="loader-container">
                    <div className="loader-container-inner">
                      <div className="text-center">
                        <Loader type="ball-grid-cy" />
                      </div>
                      <h6 className="mt-3">Please wait while we load the C.A.R.E Application</h6>
                    </div>
                  </div>
                )
              }
              >
                <div
                  id='appParent'
                  style={{
                    display: 'flex',
                    height: '100%',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    position: 'relative'
                  }
                  }
                >
                  <ChangelogModal />
                  <Header appTitle="C.A.R.E" />
                  <Application />
                  <Footer version="2.0.3" copy={`${new Date().getFullYear()} Aldridge, All Rights Reserved`} />
                  <ServiceWorkerWrapper />
                </div>
              </Suspense>
            </BrowserRouter>
          </OfflineDataProvider>
        </UserProvider>
      </OfflineProvider>
    </RecoilRoot>
    ,
    rootElement
  );
};

renderApp();
