import React from 'react';
import {
  NavLink as RouterLink,
  matchPath,
  useLocation
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, ListItem } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { basicStyles } from '../Pages/theme';

const NavItem = ({ href, icon: Icon, title }) => {
  const classes = basicStyles();
  const location = useLocation();

  const active = href
    ? !!matchPath(
      {
        path: href,
        end: false
      },
      location.pathname
    )
    : false;

  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        py: 0,
        width: '100%'
      }}
    >
      {Icon && <FontAwesomeIcon className={`${classes.icon} fa-fw`} icon={Icon} />}
      <Button
        style={{ width: '100%', justifyContent: 'flex-start' }}
        component={RouterLink}
        sx={{
          color: 'text.secondary',
          fontWeight: 'medium',
          letterSpacing: 0,
          py: 1.25,
          textTransform: 'none',
          ...(active && {
            color: 'primary.main'
          }),
          '& svg': {
            mr: 1
          }
        }}
        to={href}
      >
        <span>{title}</span>
      </Button>
    </ListItem>
  );
};

NavItem.propTypes = {
  href: PropTypes.string.isRequired,
  icon: PropTypes.objectOf(PropTypes.any).isRequired,
  title: PropTypes.string.isRequired
};


export default NavItem;
