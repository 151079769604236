import React from 'react';
import PropTypes from 'prop-types';
import {
  Table, TableRow, TableCell, TableBody
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import ErrorBoundary from '../_helpers/ErrorBoundary';

const SortableList = (props) => {
  const {
    options, displayKey, updateOrder, removeFromList
  } = props;

  const onDrop = (ev) => {
    ev.preventDefault();
    const obj = ev.dataTransfer.getData('text');
    const insertBef = ev.target.nodeName.toLowerCase() === 'td' ? ev.target.parentNode : ev.target;
    ev.currentTarget.insertBefore(document.getElementById(obj), insertBef);

    const rows = [...ev.currentTarget.rows].map((res) => res.id);
    const reOrderedOptions = [];
    rows.forEach((r) => {
      reOrderedOptions.push(options.filter((res) => res.id === r)[0]);
    });
    updateOrder(reOrderedOptions);
  };
  const allowDrop = (ev) => {
    ev.preventDefault();
  };
  const onDrag = (ev) => {
    ev.dataTransfer.setData('text', ev.target.id);
  };
  return (
    <ErrorBoundary componentName="SortableList">
      <Table>
        <TableBody onDrop={onDrop} onDragOver={allowDrop}>
          {options.map((res, idx) => (
            <TableRow draggable onDragStart={onDrag} id={res.id} key={res.id}>
              <TableCell>
                {idx + 1}
              </TableCell>
              <TableCell>
                {res.data[displayKey]}
              </TableCell>
              <TableCell align="right"><Delete onClick={() => { removeFromList(idx); }} /></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </ErrorBoundary>
  );
};

SortableList.propTypes = {
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
  displayKey: PropTypes.string.isRequired,
  updateOrder: PropTypes.func.isRequired,
  removeFromList: PropTypes.func.isRequired
};
SortableList.defaultProps = {
};

export default SortableList;
