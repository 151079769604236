/* eslint-disable no-unused-vars */
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Menu, MenuItem, AppBar, Toolbar, useMediaQuery, useTheme, Drawer } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Menu as MenuIcon } from '@material-ui/icons';
import { UserContext } from '../providers/UserProvider';
import Logo from './Logo/Logo';
import DashboardSidebar from './DashboardSidebar';
import IndexedDbWrapper from '../utils/indexedDBWrapper';
import { config } from '../firebase';
import OnlineStatus from './OnlineStatus';
import CloudSync from './CloudSync';
import ServiceWorkerStatus from '../utils/serviceWorkerInstalled';
import ErrorBoundary from '../_helpers/ErrorBoundary';


const Header = (props) => {
  const user = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawer, setDrawer] = useState(false);
  const { appTitle } = props;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));
  const matches2 = useMediaQuery(theme.breakpoints.up('md'));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <ErrorBoundary componentName="Header">
      <AppBar className='header' position='relative'>
        <Toolbar className='navbar' style={{ padding: '0px 4px 0px 0px' }}>
          <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '0px' }}>
            <Link to='/' style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
              <div style={{ padding: '0px 8px', display: 'flex', alignItems: 'center' }} >
                <Logo />
              </div>
            </Link>
            <span style={{ color: 'white', fontSize: '1.25rem' }}>{appTitle}</span>
            &nbsp;
            &nbsp;
            &nbsp;
            &nbsp;
            {/* <span><ServiceWorkerStatus /></span> */}
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {matches ? <>
                {user && (user.admin) ? (
                  <>
                    <Button aria-controls="simple-menu" aria-haspopup="true" style={{ margin: '0px -10px' }} onClick={handleClick}>
                      Admin
                    </Button>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem onClick={handleClose}><Link to="/InspectionTypes">Inspection Types</Link></MenuItem>
                      <MenuItem onClick={handleClose}><Link to="/Yards">Yards</Link></MenuItem>
                      <MenuItem onClick={handleClose}><Link to="/UserAssignment">User Assignment</Link></MenuItem>
                      <MenuItem onClick={handleClose}><Link to="/Categories">Categories</Link></MenuItem>
                      <MenuItem onClick={handleClose}><Link to="/Subcategories">Subcategories</Link></MenuItem>
                    </Menu>
                  </>
                ) : null}
                {
                  user && (user.user || user.admin) ? (
                    <>
                      <MenuItem style={{ marginRight: '-10px' }}><Link style={{ color: '#F8F8F8', textDecoration: 'none' }} to="/Inspections">Inspections</Link></MenuItem>
                    </>
                  ) : <></>
                } </>
                : null} </div>
            <CloudSync />
            <div style={{ marginRight: '4px' }}><OnlineStatus /></div>
            <IndexedDbWrapper projectId={config.projectId} />
            {user && matches && (user.user || user.admin) ? (
              <><MenuItem><MenuIcon style={{ margin: '0px -10px 0px -10px' }} onClick={() => { setDrawer(true); }} /></MenuItem>
                <Drawer
                  anchor='right'
                  onClose={() => setDrawer(false)}
                  open={drawer}
                  variant='temporary'
                  key='MoreDrawer'
                  PaperProps={{
                    sx: {
                      width: 256
                    }
                  }}
                >
                  <DashboardSidebar />
                </Drawer></>) : <> </>}
          </div>

        </Toolbar>
        <div className="brandContainer" style={{ lineHeight: '1', backgroundColor: '#FAFAFA' }}>
          <div className="brand" />
          <div style={{
            display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', paddingTop: '5px'
          }}
          >
            <div style={{ color: 'black', paddingLeft: '5px', fontVariant: 'small-caps', fontWeight: 'bold' }}>
              {matches2 ? "Commit. Act. Respond. Evaluate." : ""}
            </div>
            <a href="https://www.aldridgegroup.com/">aldridgegroup.com</a>
          </div>
        </div>
      </AppBar>
    </ErrorBoundary>
  );
};

Header.propTypes = {
  appTitle: PropTypes.string,
};
Header.defaultProps = {
  appTitle: '',
};

export default Header;
