/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react';
import {
  Modal,
  useMediaQuery,
  useTheme, Backdrop, Grid
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useRecoilState } from 'recoil';
import { firestore } from './firebase';
import { _ShowChangelogModal } from './_Recoil/atoms';
import { basicStyles } from './theme/index';
import { UserContext } from './providers/UserProvider';
import ErrorBoundary from './_helpers/ErrorBoundary';

const ChangelogModal = () => {
  const [logs, setLogs] = useState([]);
  const user = useContext(UserContext);
  const [ShowChangelogModal, setShowChangelogModal] = useRecoilState(_ShowChangelogModal);
  useEffect(() => {
    let mounted = true;
    setShowChangelogModal(localStorage.getItem('ChangelogShown') === null && (typeof user !== 'undefined' && user !== null));
    return () => (mounted = false);
  }, [user]);

  useEffect(() => {
    let mounted = true;
    if (ShowChangelogModal) {
      firestore.collection('Changelog').orderBy('Version', 'desc').onSnapshot(docs => {
        const allLogs = [];
        docs.forEach(doc => allLogs.push(doc.data()));
        if (mounted)
          setLogs(allLogs);
      });
    }
    return () => (mounted = false);
  }, [ShowChangelogModal]);

  const close = () => {
    localStorage.setItem('ChangelogShown', 'true');
    setShowChangelogModal(false);
  };
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const customClasses = makeStyles({
    version: {
      fontWeight: 'bold',
      width: '100%',
      fontSize: '110%',
      textAlign: 'left'
    },
    deployDate: {
      fontWeight: 'bold',
      width: '100%',
      fontSize: '110%',
      textAlign: 'right'
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      outline: 'none',
      height: '80%',
      width: '60%',
      overflow: 'auto',
      padding: theme.spacing(1, 2),
      [theme.breakpoints.down('xs')]: {
        padding: '6px 8px'
      }
    },
  })();
  const classes = basicStyles();

  return (
    <ErrorBoundary componentName="Changelog Modal" user={user}>
      <Grid container>
        <Modal
          // open
          open={ShowChangelogModal}
          // open={false}
          onClose={close}
          BackdropComponent={Backdrop}
          disablePortal
          className={classes.modal}
        >
          <div className={customClasses.paper} style={{ paddingTop: '0px' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: matches ? '6px 0px 5px 4px' : '12px 0px 10px 10px',
                position: 'sticky', top: '-1px', left: 0, right: 0,
                backgroundColor: 'white',
                zIndex: '2100'
              }}
            >
              <h1>What&apos;s New!</h1>
              <button style={{ minWidth: '50px', backgroundColor: 'transparent', border: 'none', cursor: 'pointer', fontSize: '20px', textAlign: 'right', paddingRight: matches ? '6px' : '12px', color: '#f50057' }} type="button" onClick={close}><b>X</b></button>
            </div>

            <div
              style={{ position: 'relative', flex: '1 1 auto', padding: matches ? '6px' : '12px', paddingTop: '0px' }}
            >
              {logs.map((log) => (
                <div key={log.id} >
                  <div style={{ border: '1px ridge gray', marginTop: '6px' }} />
                  <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px 0px' }}>
                    <div className={customClasses.version}>{log.Version}</div>
                    <i className={customClasses.deployDate}>{log.DeployDate}</i>
                  </div>
                  <ul>{log.Changes.map((change, idx) => (
                    <li style={{ textAlign: 'left', marginLeft: '28px', padding: '4px 0px' }} key={idx.toString()}>{change.Change}</li>
                  ))}</ul>
                </div>
              ))}
            </div>
          </div>

        </Modal >
      </Grid>
    </ErrorBoundary>
  );
};

export default ChangelogModal;