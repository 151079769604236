/* eslint-disable import/prefer-default-export */
import { useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';

const useInitializeOffline = () => {
  const [syncs, setSyncs] = useState(new Map());
  const [firestore, setFirestore] = useState({});
  const [initialCount, setInitialCount] = useState(0);
  const toastRefs = useRef({});
  const [toastStack, setToastStack] = useState([])

  useEffect(() => {
    toast.dismiss(toastRefs.current)
    if (toastStack.length > 0)
      toastRefs.current = toast.dark(`While offline data is initializing, data may appear on page slower. Downloading ${toastStack.join(', ')}`, { autoClose: false, position: 'bottom-right', id: toastStack.join('').replace(/\w/gi, '') })
  }, [toastStack])

  const addSync = (key, firestoreStatement, toastMessage) => {
    const updatedSyncs = new Map(syncs);
    updatedSyncs.set(key, { toast: toastMessage, firestore: firestoreStatement });
    setSyncs((prevSync) => new Map([...prevSync, ...updatedSyncs]));
  }
  const syncData = () => {
    if (!navigator.onLine) return;
    const updatedToastStack = [...toastStack]
    syncs.forEach((value) => {
      if (updatedToastStack.indexOf(value.toast) === -1)
        updatedToastStack.push(value.toast)
      setToastStack(prev => ([...new Set([...prev, ...updatedToastStack])]))
      value.firestore.query.get().then((res) => {
        if (value.firestore.storeInAtom) {
          const docs = [];
          res.forEach(doc => docs.push(doc.data()));
          value.firestore.atomSetter(docs);
        }
        if (value.firestore.getSub) {
          const obsPromise = [];
          res.forEach(doc => {
            obsPromise.push(firestore.collection(`${doc.ref.path}/${value.firestore.subcollection}`).get())
          })
          Promise.all(obsPromise).then(() => {
            setToastStack(prev => prev.filter(p => p !== value.toast))
          }).catch((err) => {
            // eslint-disable-next-line no-console
            console.log(err);
          });
        } else {
          setToastStack(prev => prev.filter(p => p !== value.toast))
        }
      })
    })
  }
  useEffect(() => {
    if (initialCount !== 0 && initialCount === syncs.size) {
      syncData();
    }
  }, [syncs]);

  return {
    syncs, addSync, syncData, setInitialCount, setFirestore
  }
}

export { useInitializeOffline };
