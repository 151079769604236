import { faHome, faSearchPlus, faFolders, faFolderTree, faUserFriends, faClipboard, faUserHardHat } from '@fortawesome/pro-regular-svg-icons';

// eslint-disable-next-line import/prefer-default-export
export const MenuData = [
  {
    href: '/',
    icon: faHome,
    title: 'Home',
    admin: false
  },
  {
    href: '/Inspections/new',
    icon: faSearchPlus,
    title: 'New Inspection',
    admin: false,
  },
  {
    href: '/InspectionTypes',
    icon: faClipboard,
    title: 'Inspection Types',
    admin: true
  },
  {
    href: '/Yards',
    icon: faUserHardHat,
    title: 'Yards',
    admin: true
  },

  {
    href: '/UserAssignment',
    icon: faUserFriends,
    title: 'User Assignment',
    admin: true
  },
  {
    href: '/Categories',
    icon: faFolders,
    title: 'Categories',
    admin: true
  },
  {
    href: '/Subcategories',
    icon: faFolderTree,
    title: 'Subcategories',
    admin: true
  },
];
export default MenuData;