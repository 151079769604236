/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Button,
  TableBody,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import { Delete } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { useRecoilState } from 'recoil';
import Confirm from '../_GlobalComponents/Confirm';
import uuid from '../_helpers/uuid';
import { firestore } from '../firebase';
import InspectionsFilter from '../_DataComponents/InspectionsFilter';
import { _InitedOffline, _InspectionsFilterQuery, _AllCategories, _AllSubcategories, _AllInspectionTypes } from '../_Recoil/atoms';
import { UserContext } from '../providers/UserProvider';
import ErrorBoundary from '../_helpers/ErrorBoundary';

const InspectionsListUser = (props) => {
  const { history } = props;
  const user = useContext(UserContext);
  const [InspectionsQuery] = useRecoilState(_InspectionsFilterQuery);
  const [setups, setSetups] = useState([]);
  const themes = useTheme();
  const matches = useMediaQuery(themes.breakpoints.down('md'));

  useEffect(() => {
    let mounted = true;
    try {
      const colRef = (() => {
        let ref = firestore.collection('Inspections');

        if (!user.admin) {
          ref = ref.where('data.Inspector', '==', user.name);
        }
        if (user.admin) {
          if (InspectionsQuery.Inspector !== '') {
            ref = ref.where('data.Inspector', '==', InspectionsQuery.Inspector);
          }
        }
        if (InspectionsQuery.InspectionDateDate !== '') {
          ref = ref.where('data.InspectionDate_Date', '==', InspectionsQuery.InspectionDateDate);
        }
        if (InspectionsQuery.JobNumberDisplay !== '') {
          ref = ref.where('data.JobNumberDisplay', '==', InspectionsQuery.JobNumberDisplay);
        }
        if (InspectionsQuery.Status !== '') {
          ref = ref.where('data.Status', '==', InspectionsQuery.Status);
        }
        ref = ref.orderBy('data.InspectionDate', 'desc');
        return ref;
      })();

      colRef.onSnapshot((snap) => {
        const allSetups = [];
        snap.forEach((doc) => {
          allSetups.push(doc.data());
        });
        if (mounted)
          setSetups(allSetups);
      }, (err) => {
        // eslint-disable-next-line
        console.log(err);
      });
    } catch (err) {
      // eslint-disable-next-line
      console.log(err.message);
      toast.error(err.message);
    }
    return () => { mounted = false };
  }, [InspectionsQuery]);

  const onSelect = (row) => {
    history.push(`/Inspections/${row.id}`);
  };
  const [deleteState, setDeleteState] = useState({
    proceedLabel: 'Delete',
    cancelLabel: 'Cancel',
    title: 'Delete Inspection',
    modalBody: 'Are you sure you want to remove this Inspection?',
    show: false,
    // eslint-disable-next-line no-use-before-define
    proceed: (approved, r) => { removeRecord(approved, r); },
    enableEscape: true
  });
  const removeRecord = (a, r) => {
    if (a) {
      if (r.idx !== '') {
        firestore.collection('Inspections').doc(r.idx).delete().then(() => {
          toast.success('Inspection Deleted Successfully.')
        });
      }
    }
    setDeleteState({ ...deleteState, show: false });
  };
  const handleDelete = (idx) => {
    setDeleteState({ ...deleteState, show: true, record: { idx } });
  };

  const useStyles = makeStyles((theme) => ({
    cell: {
      [theme.breakpoints.down('sm')]: {
        padding: '3px',
        textAlign: 'left'
      },
      [theme.breakpoints.up('sm')]: {
        padding: '16px',
      },
    },
    cellHeader: {
      fontWeight: 'bold',
      [theme.breakpoints.down('sm')]: {
        padding: '3px',
      },
      [theme.breakpoints.up('sm')]: {
        padding: '16px',
      },
    },
    row: {
      '&:nth-child(odd)': {
        backgroundColor: '#efefef'
      }
    },

  }));

  const customClasses = useStyles();

  window.currentCategory = '';
  window.currentSubcategory = '';

  return (
    <ErrorBoundary componentName="Inspections List User">
      <div style={{ padding: '5px' }} />
      <Confirm
        proceedLabel={deleteState.proceedLabel}
        cancelLabel={deleteState.cancelLabel}
        title={deleteState.title}
        modalBody={deleteState.modalBody}
        show={deleteState.show}
        proceed={deleteState.proceed}
        enableEscape={deleteState.enableEscape}
        record={deleteState.record}
      />
      <div style={{
        position: 'sticky', top: 0, left: 0, right: 0, backgroundColor: 'white', width: '100%', display: 'flex', zIndex: 1000
      }}
      >
        <div align='left' style={{ margin: '12px', flex: 1, justifyContent: 'flex-start' }}>
          <InspectionsFilter /> </div>
        {matches ? null : <div align='right' style={{ margin: '16px', marginTop: '20px' }}>

          <Link to="Inspections/new"><Button variant="contained" color="primary">New Inspection</Button></Link> </div>}
      </div>
      <Grid container>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={customClasses.cellHeader}>Location</TableCell>
                <TableCell className={customClasses.cellHeader}>Inspection Date</TableCell>
                <TableCell className={customClasses.cellHeader}>Submitted By</TableCell>
                <TableCell className={customClasses.cellHeader} colSpan={2}>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {setups.map((row) => (
                <TableRow className={`${customClasses.cell} ${customClasses.row}`} style={{ cursor: 'pointer' }} hover onClick={() => onSelect(row)} key={uuid()}>
                  { }
                  {
                    (row.data.Location === 'Job') ?
                      <TableCell className={customClasses.cell}>{row.data.JobNumberDisplay}</TableCell> :
                      <TableCell className={customClasses.cell}>{row.data.YardDisplay}</TableCell>
                  }
                  <TableCell className={customClasses.cell}>{row.data.InspectionDate !== '' ? dayjs(row.data.InspectionDate).format('MMM DD YYYY, hh:mm A') : ''}</TableCell>
                  <TableCell className={customClasses.cell}>{row.CreatedBy}</TableCell>
                  <TableCell className={customClasses.cell} colSpan={2}><Grid container style={{ alignItems: 'center' }}><Grid item xs={6} style={{ textAlign: 'left' }}> {row.data.Status}</Grid>{row.data.Status === "Complete" && !user.admin ? <> </> : <Grid item xs={6} style={{ textAlign: 'right' }}><Delete onClick={(e) => { e.stopPropagation(); handleDelete(row.id); }} /></Grid>}</Grid></TableCell>

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </ErrorBoundary>
  );
};

InspectionsListUser.propTypes = {
  history: PropTypes.objectOf(PropTypes.any)
};
InspectionsListUser.defaultProps = {
  history: {}
};

export default InspectionsListUser;