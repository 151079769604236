import React, { useState, createContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';
import {
  auth, functions
} from '../firebase';

export const UserContext = createContext({ user: null });
const UserProvider = (props) => {
  const [user, setUser] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [signingIn, setSiginingIn] = useState(false);
  const { children } = props;
  useEffect(() => {
    auth.onAuthStateChanged(async () => {
      if (auth.currentUser) {
        if (localStorage.getItem('token') !== null) {
          setUser(JSON.parse(localStorage.getItem('token')));
        }
        auth.currentUser.getIdToken(true).then((idToken) => {
          const checkIfUserExists = functions.httpsCallable('userExists');
          setSiginingIn(true);
          checkIfUserExists({ idToken, host: window.location.host }).then(async () => {
            const token = await auth.currentUser.getIdTokenResult(true);
            setUser(token.claims);
            setSiginingIn(false);
            localStorage.setItem('token', JSON.stringify(token.claims));
          }).catch((err) => {
            // eslint-disable-next-line
            console.log(err);
            setSiginingIn(false);
            toast.error(err.message, { autoClose: 8000 });
          });
        });
      }
      else {
        setSiginingIn(false);
        setUser(null);
      }
    });
  }, []);

  return (
    <UserContext.Provider value={user}>
      {signingIn ? <div style={{
        display: 'flex',
        position: 'fixed',
        zIndex: 2600,
        backgroundColor: '#fff',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        opacity: '.70'
      }}>
        <Loader
          type="TailSpin"
          color="#3b78e7"
          height={150}
          width={150}
        />
        Signing In...

      </div> : null
      }
      {children}
    </UserContext.Provider >
  );
};

UserProvider.propTypes = {
  children: PropTypes.objectOf(PropTypes.any)
};
UserProvider.defaultProps = {
  children: []
};
export default UserProvider;
