import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Button,
  Grid,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { createTheme, makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import { Delete, ExpandMore, Add } from '@material-ui/icons';
import { Link, useHistory, Prompt } from 'react-router-dom';
import dayjs from 'dayjs';
import firebase from 'firebase';
import Confirm from '../_GlobalComponents/Confirm';
import { firestore } from '../firebase';
import { UserContext } from '../providers/UserProvider';
import {
  GreenButton, GreenLink, RedButton, RedLink
} from '../providers/MuiTheme';
import ErrorBoundary from '../_helpers/ErrorBoundary';

const Subcategory = (props) => {
  const {
    Inspection, categoryKey, subcategory, InspectionSubmit, Observations, SafeObservations
  } = props;
  const history = useHistory();
  const user = useContext(UserContext);

  const AtRiskCount = Observations.filter((res) => res.data.ObservationType === 'At-Risk').length;
  // const SafeCount = Observations.filter((res) => res.data.ObservationType === 'Safe').length;
  const [SafeCount, setSafeCount] = useState(Observations.filter((res) => res.data.ObservationType === 'Safe').length);
  const hasOpenAtRisk = Observations.filter((res) => res.data.ObservationType === 'At-Risk' && res.data.Status === 'Open').length;
  // eslint-disable-next-line no-unused-vars
  // const [sObservation, setSObservation] = useState([]);
  const [deleteState, setDeleteState] = useState({
    proceedLabel: 'Delete',
    cancelLabel: 'Cancel',
    title: 'Delete Observation',
    modalBody: 'Are you sure you want to remove this Observation?',
    show: false,
    // eslint-disable-next-line no-use-before-define
    proceed: (approved, r) => { removeRecord(approved, r); },
    enableEscape: true
  });

  const removeRecord = (a, r) => {
    console.log(r.sObservation);
    if (a) {
      if (r.idx !== '')
        firestore.collection(`Inspections/${Inspection.id}/Observations`).doc(r.idx).delete();
      if (typeof r.arrayIdx !== 'undefined') {
        const uObservation = [...r.sObservation];
        uObservation.splice(r.arrayIdx, 1);
        console.log(uObservation);
      }
      if (r.type === "Safe")
        setSafeCount(r.SafeCount - 1);
    }
    setDeleteState({ ...deleteState, show: false });
  };
  const handleDelete = (idx, type, i) => {
    console.log(i);
    if (typeof idx !== 'undefined' && idx !== '') {
      setDeleteState({ ...deleteState, show: true, record: { idx, type, SafeCount, arrayIdx: i } });
    }
    if (typeof i !== 'undefined') {
      const arrIdx = SafeObservations.findIndex(res => res.data.CategoryID === categoryKey && res.data.SubcategoryID === subcategory.id);
      if (arrIdx !== -1) {
        SafeObservations.splice(arrIdx, 1);
        setSafeCount(SafeCount - 1);
      }
    }
  };

  useEffect(() => {
    let mounted = true;
    if (mounted)
      setSafeCount(Observations.filter((res) => res.data.ObservationType === 'Safe').length);
    return () => { mounted = false; };
  }, [Observations]);

  const disableForUsers = Inspection.data.Status === 'Complete' && !user.admin;

  // const addSafe = async (e) => {
  //   e.stopPropagation();
  //   await InspectionSubmit(false);
  //   const docRef = firestore.collection('Inspections').doc(Inspection.id).collection('Observations').doc();
  //   const newSafeRecord = {
  //     id: docRef.id,
  //     CreatedDate: new Date().toJSON(),
  //     CreatedBy: user.name,
  //     ModifiedBy: user.email,
  //     ModifiedDate: new Date().toJSON(),
  //     data: {
  //       InspectionID: Inspection.id,
  //       CategoryID: categoryKey,
  //       SubcategoryID: subcategory.id,
  //       Severity: '',
  //       RootCause: '',
  //       ActionTaken: '',
  //       CorrectedBy: '',
  //       CompletedDate: '',
  //       Comments: '',
  //       ObservationType: 'Safe',
  //       Status: 'Complete',
  //     }
  //   };
  //   docRef.set(newSafeRecord)
  //   toast.success('Safe Observation Recorded.');
  // };

  const addSafe = (e) => {
    e.stopPropagation();
    SafeObservations.push({
      CreatedDate: new Date().toJSON(),
      CreatedBy: user.name,
      ModifiedBy: user.email,
      ModifiedDate: new Date().toJSON(),
      data: {
        InspectionID: Inspection.id,
        CategoryID: categoryKey,
        SubcategoryID: subcategory.id,
        Severity: '',
        RootCause: '',
        ActionTaken: '',
        CorrectedBy: '',
        CompletedDate: '',
        Comments: '',
        ObservationType: 'Safe',
        Status: 'Complete',
      }
    });
    firebase.analytics().logEvent('safe_observation', { inspection_id: Inspection.id, category_id: categoryKey, subcategory_id: subcategory.id, user: user.name });
    setSafeCount(SafeCount + 1);
    toast.success('Safe Observation Recorded.');
  };

  const addUnsafe = async (e) => {
    e.stopPropagation();
    await InspectionSubmit();
    const docRef = firestore.collection('Inspections').doc(Inspection.id).collection('Observations').doc();
    const newAtRiskRecord = {
      id: docRef.id,
      CreatedDate: new Date().toJSON(),
      CreatedBy: user.name,
      ModifiedBy: user.email,
      ModifiedDate: new Date().toJSON(),
      data: {
        InspectionID: Inspection.id,
        CategoryID: categoryKey,
        SubcategoryID: subcategory.id,
        InspectionStatus: 'Open',
        Severity: '',
        RootCause: '',
        ActionTaken: '',
        CorrectedBy: '',
        CompletedDate: '',
        Comments: '',
        ObservationType: 'At-Risk',
        Status: 'Open',
      }
    };
    docRef.set(newAtRiskRecord);
    firebase.analytics().logEvent('at_risk_observation_created', { observation_id: docRef.id, inspection_id: Inspection.id, category_id: categoryKey, subcategory_id: subcategory.id, user: user.name });
    history.push(`/Inspections/${Inspection.id}/Observations/${newAtRiskRecord.id}`);
  };

  const theme = createTheme();
  const useStyles = makeStyles({
    // style rule
    root: {
      color: 'white',
      borderRadius: '10%',
      margin: theme.spacing(0, 0.25),
      flexWrap: 'wrap'
    },
    linkRoot: {
      width: '100%',
      flexWrap: 'wrap'
    },
  });
  const classes = {
    root: useStyles(),
    GreenButton: GreenButton(),
    RedButton: RedButton(),
    RedLink: RedLink(),
    GreenLink: GreenLink(),
  };

  const themes = useTheme();
  const matches = useMediaQuery(themes.breakpoints.down('xs'));
  const propKey = subcategory?.data?.Subcategory?.replace(/[^0-9a-z]/gi, '');
  const openAccordion = () => {
    window.currentSubcategory = window.currentSubcategory === propKey ? '' : propKey;
  };

  const ObEntry = (res, idx) => (
    <Grid container justifyContent='flex-start' key={typeof res.id === 'undefined' ? res.ModifiedDate : res.id}>
      <Grid item xs={11}>
        <Link
          to={`/Inspections/${res.data.InspectionID}/Observations/${res.id}`}
          className={`${classes.root.linkRoot} ${res.data.ObservationType === 'Safe' ? classes.GreenLink.root : classes.RedLink.root}`}
        >
          {matches ?
            <>
              <Grid item xs={6}>{res.data.Status}</Grid>
              <Grid item xs={6}>{dayjs(res.CreatedDate).format('MM/DD/YYYY')}</Grid>
              <Grid item xs={6}>{res.data.ObservationType}</Grid>
              <Grid item xs={6}>{res.CreatedBy}</Grid> </>
            :
            <>
              <Grid item md={3}>{res.data.Status}</Grid>
              <Grid item md={3}>{res.data.ObservationType}</Grid>
              <Grid item md={3}>{dayjs(res.CreatedDate).format('MM/DD/YYYY h:mm A')}</Grid>
              <Grid item md={3}>{res.CreatedBy}</Grid> </>
          }
        </Link>
      </Grid>
      <Grid container item xs={1} justifyContent='flex-end' style={{ cursor: 'pointer' }}>
        {/* {(typeof res.id === 'undefined' || res.id === '') ? "This record is pending. Save the inspection to update the counter or delete this line." : null} */}
        {disableForUsers ? null : <Delete onClick={() => handleDelete(res.id, res.data.ObservationType, idx)} />}
      </Grid>
    </Grid>
  );

  return (
    <ErrorBoundary componentName="Subcategory In Observations Accordion">
      <Confirm
        proceedLabel={deleteState.proceedLabel}
        cancelLabel={deleteState.cancelLabel}
        title={deleteState.title}
        modalBody={deleteState.modalBody}
        show={deleteState.show}
        proceed={deleteState.proceed}
        enableEscape={deleteState.enableEscape}
        record={deleteState.record}
      />
      {/* <Accordion key={subcategory.id}> */}
      <Prompt
        when={SafeObservations.length > 0}
        message="There are unsaved Observations. Please save the Inspection before continuing...If you hit OK before saving, you will lose any Observations you have created."
      />
      <Accordion key={subcategory.id} defaultExpanded={window.currentSubcategory === propKey || false} id={propKey}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          onClick={openAccordion}
          aria-controls={`${subcategory.id}-content`}
          id={`${subcategory.id}-content`}
        >
          <div style={{
            display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'flex-end'
          }}
          >
            <Grid container item xs={12}>
              <Grid container alignItems='center' item xs={8} style={{ color: hasOpenAtRisk > 0 ? '#d92550' : '#AAA' }}>
                {subcategory.data.Subcategory}
              </Grid>
              <Grid container item xs={4} justifyContent='flex-end' alignItems='center' wrap='nowrap'>
                {
                  SafeCount > 0
                    ? <Button className={`${classes.root.root} ${classes.GreenButton.root}`} style={{ padding: '3px', minWidth: '32px' }} onClick={addSafe} disabled={disableForUsers}>{SafeCount}</Button>
                    : <IconButton className={`${classes.root.root} ${classes.GreenButton.root}`} style={{ padding: '3px' }} onClick={addSafe} disabled={disableForUsers}><Add /></IconButton>
                }
                {
                  AtRiskCount > 0
                    ? <Button className={`${classes.root.root} ${classes.RedButton.root}`} style={{ padding: '3px', minWidth: '32px' }} onClick={addUnsafe} disabled={disableForUsers}>{AtRiskCount}</Button>
                    : <IconButton className={`${classes.root.root} ${classes.RedButton.root}`} style={{ padding: '3px' }} onClick={addUnsafe} disabled={disableForUsers} ><Add /></IconButton>
                }
              </Grid>
            </Grid>
          </div>
        </AccordionSummary>
        <AccordionDetails style={{ flexDirection: 'column', margin: "0 auto" }}>
          {SafeObservations.filter(res => res.data.CategoryID === categoryKey && res.data.SubcategoryID === subcategory.id)?.length > 0 ?
            SafeObservations.filter(res => res.data.CategoryID === categoryKey && res.data.SubcategoryID === subcategory.id).map((so, idx) => (
              ObEntry(so, idx)
            ))
            : null}
          {Observations?.length > 0 ?
            Observations.map((res) => (
              ObEntry(res)
            )) : null}
        </AccordionDetails>
      </Accordion>
    </ErrorBoundary>
  );
};

Subcategory.propTypes = {
  Inspection: PropTypes.objectOf(PropTypes.any).isRequired,
  subcategory: PropTypes.objectOf(PropTypes.any).isRequired,
  InspectionSubmit: PropTypes.func.isRequired,
  categoryKey: PropTypes.string.isRequired,
  Observations: PropTypes.arrayOf(PropTypes.object).isRequired,
  SafeObservations: PropTypes.arrayOf(PropTypes.object).isRequired,
};
export default Subcategory;
