import React, { useContext, useEffect } from 'react';
import firebase from 'firebase';
import { Switch, Route } from 'react-router-dom';
import { UserContext } from '../providers/UserProvider';
import GlobalStyles from '../_GlobalComponents/GlobalStyles';
import SignIn from './SignIn';
import Unauthorized from './Unauthorized';
import NoMatch from './NoMatch';
import InspectionsListUser from './InspectionsListUser';
import Inspections from './Inspections';
import { SubcategoriesList, Subcategories } from './Subcategories';
import { CategoriesList, Categories } from './Categories';
import { InspectionTypesList, InspectionTypes } from './InspectionTypes';
import { UserAssignmentList, UserAssignment } from './UserAssignment';
import { Observations } from './Observations';
import SignOut from './SignOut';
import Test from './Test';
import Changelog from './Changelog';
import ErrorBoundary from '../_helpers/ErrorBoundary';
import { YardsList, Yards } from './Yards';

const Application = () => {
  const user = useContext(UserContext);
  const analytics = firebase.analytics();

  const page = () => {
    // return <SignIn />;
    // eslint-disable-next-line no-unreachable
    if (!user) {
      return <SignIn />;
    }
    if (user && (user.admin || user.user)) {
      return (
        <>
          <GlobalStyles />
          <Switch>
            <Route exact path="/" component={InspectionsListUser} />
            <Route exact path="/Inspections" component={InspectionsListUser} />
            <Route exact path="/Inspections/:id" component={Inspections} />
            <Route exact path="/Yards" component={user.admin ? YardsList : NoMatch} />
            <Route exact path="/Yards/new" component={user.admin ? Yards : NoMatch} />
            <Route exact path="/Yards/:id" component={user.admin ? Yards : NoMatch} />
            <Route exact path="/Subcategories" component={user.admin ? SubcategoriesList : NoMatch} />
            <Route exact path="/Subcategories/new" component={user.admin ? Subcategories : NoMatch} />
            <Route exact path="/Subcategories/:id" component={user.admin ? Subcategories : NoMatch} />
            <Route exact path="/Categories" component={user.admin ? CategoriesList : NoMatch} />
            <Route exact path="/Categories/new" component={user.admin ? Categories : NoMatch} />
            <Route exact path="/Categories/:id" component={user.admin ? Categories : NoMatch} />
            <Route exact path="/InspectionTypes" component={user.admin ? InspectionTypesList : NoMatch} />
            <Route exact path="/InspectionTypes/new" component={user.admin ? InspectionTypes : NoMatch} />
            <Route exact path="/InspectionTypes/:id" component={user.admin ? InspectionTypes : NoMatch} />
            <Route exact path="/UserAssignment" component={user.admin ? UserAssignmentList : NoMatch} />
            <Route exact path="/UserAssignment/new" component={user.admin ? UserAssignment : NoMatch} />
            <Route exact path="/UserAssignment/:id" component={user.admin ? UserAssignment : NoMatch} />
            <Route exact path="/Inspections/:InspectionID/Observations/:id" component={Observations} />
            <Route exact path="/SignOut" component={SignOut} />
            {user.SuperAdmin ? <Route exact path="/Test" component={Test} /> : null}
            {user.SuperAdmin ? <Route exact path="/Changelog" component={Changelog} /> : null}
            {user.SuperAdmin ? <Route exact path="/Changelog/:id" component={Changelog} /> : null}
            <Route exact><NoMatch /></Route>
          </Switch>
        </>
      );
    }

    return <Unauthorized />;
  };

  useEffect(() => {
    if (user !== null && !user.unauthorized) {
      analytics.logEvent('user_login', { user: user.email });
    }
  }, [user]);
  return (
    <ErrorBoundary componentName="The Application Itself">
      <div className="appBody">
        {
          page()
        }
      </div>
    </ErrorBoundary>
  );
};
export default Application;
